import { BsCreditCard2Back } from "react-icons/bs";
export const buy_coins = [
  // {
  //   ticker: "btc",
  //   name: "Bitcoin",
  //   image: "https://content-api.changenow.io/uploads/btc_d8db07f87d.svg",
  // },
  // {
  //   ticker: "eth",
  //   name: "Ethereum",
  //   image: "https://content-api.changenow.io/uploads/eth_f4ebb54ec0.svg",
  // },
  // {
  //   ticker: "usdt",
  //   name: "Tether (OMNI)",
  //   image: "https://content-api.changenow.io/uploads/usdtomni_1_65d260d07b.svg",
  // },
  {
    ticker: "usdterc20",
    name: "USDT (ERC20)",
    image: "https://content-api.changenow.io/uploads/usdterc20_01c09cad36.svg",
  },
  {
    ticker: "usdc",
    name: "USDC (ERC20)",
    image: "https://content-api.changenow.io/uploads/usdcerc20_acd5759c8c.svg",
  },

  {
    ticker: "Credit Card",
    name: "Credit Card",
    icon: <BsCreditCard2Back className="text-xl ml-1 text-indigo-500" />,
  },
];

export const sellCoins = [
  {
    ticker: "usdterc20",
    asset: "USDT",
    name: "USDT (ERC20)",
    image: "https://content-api.changenow.io/uploads/usdterc20_01c09cad36.svg",
  },
  {
    ticker: "usdc",
    asset: "USDC",
    name: "USDC (ERC20)",
    image: "https://content-api.changenow.io/uploads/usdcerc20_acd5759c8c.svg",
  },
];
