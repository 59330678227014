import { useState } from "react";
import MultiButton from "./buttons/MultiButton";
import useAxios from "../hooks/useAxios";
import useVar from "../hooks/useVar";

const InviteEmail = ({ name }) => {
  const { myAxios } = useAxios();
  const [email, setEmail] = useState("");
  const { receivedMessage, modal } = useVar();

  const onSubmit = async (e) => {
    e.preventDefault();

    await modal.addState("pending", "Connecting to SMTP...");
    await modal.addState("pending", "Connected.");
    await modal.addState("pending", "Sending an e-mail...");

    try {
      const res = await myAxios.post("/invite", { email, name });
      await modal.addState("success", res.data.msg);
      await modal.addState("success", "Closing in 5 seconds...");
    } catch (err) {
      console.log(err);
      await modal.addState("error", err);
      await modal.addState("error", "Closing in 5 seconds...");
    }
  };

  return (
    <div className="space-y-10">
      <form className="mt-10 relative z-10 space-y-6" onSubmit={onSubmit}>
        <div className="space-y-2">
          <div className="text-base font-semibold text-[var(--chakra-colors-gray-500)] ">
            Email Address
          </div>
          <div className="relative">
            <input
              type="email"
              autoFocus={true}
              className="h-12 w-full  rounded-xl opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
              id="email"
              placeholder="Friend Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="pt-6">
          <MultiButton state={receivedMessage} />
        </div>
      </form>
    </div>
  );
};

export default InviteEmail;
