import { useEffect } from "react";
import {
  CloudArrowDownIcon,
  StarIcon,
  ArchiveBoxIcon,
  CodeBracketIcon,
} from "@heroicons/react/20/solid";

import useVar from "../hooks/useVar";

const NETWORK_NAME = process.env.REACT_APP_NETWORK?.trim();

function Mining() {
  const { secret, restApi } = useVar();

  const features = [
    {
      name: "Install Docker Desktop.",
      description: (
        <>
          <br />
          You can download the Docker Desktop from
          <a
            className="text-blue-400"
            href="https://www.docker.com/products/docker-desktop/"
          >
            Docker Desktop.
          </a>
        </>
      ),
      icon: CloudArrowDownIcon,
    },
    {
      name: "Ensure the Docker Desktop is running.",
      description: (
        <>
          <br />
          Make sure that the Docker Desktop is running, bt checkin the icon in
          the taskbar.
        </>
      ),
      icon: StarIcon,
    },
    {
      name: "Run the SC Miner.",
      description: (
        <>
          <br />
          Open a terminal and run the following command:
          <br />{" "}
          <code>
            docker run -d -e NETWORK={NETWORK_NAME} -e KEY={secret}{" "}
            us-central1-docker.pkg.dev/safecoin-1/miner/miner:latest
          </code>
        </>
      ),
      icon: CodeBracketIcon,
    },
    {
      name: "Refresh",
      description: " this page.",
      icon: ArchiveBoxIcon,
    },
  ];

  useEffect(() => {
    if (restApi) {
      restApi.isAgentRunning();
    }
  }, [restApi]);

  return (
    <div className="min-h-full sm:p-4  bg-bg text-gray-200 ">
      <div className="overflow-hidden bg-bg text-slate-100 py-4">
        <div className="mx-auto px-6 lg:px-8 lg:pr-8 lg:pt-4">
          <div className="">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-400">
              Start to be a miner right now!
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
              Install Miner Desktop – and start to get safe coins.
            </p>
            {/* <p className="mt-6 text-lg leading-8 text-gray-400">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </p> */}
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-100">
                    <feature.icon
                      className="absolute top-1 left-1 h-5 w-5 text-indigo-400"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>{" "}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mining;
