import useVar from "../hooks/useVar";
import useAxios from "../hooks/useAxios";
import MultiButton from "./buttons/MultiButton";
import useCreateTransaction from "../hooks/useCreateTransaction";

const PreviewTranasction = ({ tx, formData }) => {
  const { modal, receivedMessage } = useVar();
  const { createInternalTransaction } = useCreateTransaction(modal.addState);
  const { ownerAxios } = useAxios();
  const onSubmit = async (e) => {
    e.preventDefault();

    await modal.addState("pending", "Connecting to Blockchain...");
    await modal.addState("pending", "Connected.");

    if (tx.name === "Dispute") {
      await modal.addState("pending", "Uploading files...");

      console.log("tx", tx);
      console.log("formData", formData);

      formData.append("tx", JSON.stringify(tx));

      try {
        const res = await ownerAxios.post("/uploadFiles", formData);

        await modal.addState("success", res.data);
      } catch (err) {
        console.log(err);
        await modal.addState("error", err.response?.data ?? err.message);
      }

      // await modal.addState("pending", "Files uploaded.");

      // window.Intercom("showNewMessage");
    }

    await createInternalTransaction(tx);
  };

  return (
    <div className="space-y-6">
      {/* <div className="space-y-2">
        <div className="flex items-center justify-between bg-gray-800 rounded-xl w-full py-1.5 px-3">
          <div className="text-sm font-bold text-[var(--chakra-colors-gray-500)] ">
            Destination Address
          </div>
          <div className="text-sm font-normal text-[var(--chakra-colors-gray-600)] ">
            {shortenAddress(tx.recipient)}
          </div>
        </div>{" "}
      </div> */}
      {/* <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Transaction Fee
        </div>
      </div> */}
      <div className="space-y-2">
        <div className="text-4xl font-bold text-blue-200  text-center">
          Are you sure you want to {tx.name.toLowerCase()} this transaction?
        </div>
      </div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Description
        </div>

        <div
          className="text-sm font-normal text-[var(--chakra-colors-gray-600)] 
         p-2 rounded-xl border border-[var(--chakra-colors-gray-750)] bg-[var(--chakra-colors-gray-850)] flex justify-between space-x-4"
        >
          {tx.payload.description}
        </div>
      </div>
      {tx.name === "NotShone" && (
        <div className="space-y-2">
          <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
            Comments:
          </div>

          <div
            className="text-sm font-normal text-[var(--chakra-colors-gray-600)] 
         p-2 rounded-xl border border-[var(--chakra-colors-gray-750)] bg-[var(--chakra-colors-gray-850)] flex justify-between space-x-4"
          >
            Please uploads the required evidences in the transaction history
            section. You will get a decision in 48 hours by the AI system after
            the information is verified from both parties.
          </div>
        </div>
      )}

      <div className="space-y-2">
        <div className="text-2xl font-bold text-gray-200  text-center">
          {tx.amount.toFixed(2)} USDT
        </div>
        {/* <div className="text-xl text-[var(--chakra-colors-gray-500)] text-center">
          $ {tx.amountUSD.toFixed(2)}
        </div> */}
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="space-y-2">
          <MultiButton state={receivedMessage} />
        </div>
      </form>
    </div>
  );
};

export default PreviewTranasction;
