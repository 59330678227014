import { useMemo } from "react";
import {
  listToHistory,
  convertToDate,
  countHistory,
} from "../js/HistoryFunctions";

const useHistory = (account) => {
  const history = useMemo(() => {
    const history = {};

    if (account === undefined) return [];

    listToHistory(account?.escrow?.Send, "Send", history);
    listToHistory(account?.escrow?.Recv, "Recv", history);

    const historyList = [];
    Object.keys(history).forEach(function (date) {
      const list = history[date].sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });

      historyList.unshift({ date, list });
    });

    return historyList.sort(function (a, b) {
      return convertToDate(b.date) - convertToDate(a.date);
    });
  }, [account]);

  const historyCount = useMemo(() => {
    if (account === undefined) return 0;
    return (
      countHistory(account?.escrow?.Send, "Send") +
      countHistory(account?.escrow?.Recv, "Recv")
    );
  }, [account]);

  return [history, historyCount];
};

export default useHistory;
