import { useCallback } from "react";
import { SC_FEE } from "../js/utils";
import useVar from "../hooks/useVar";
import useAxios from "./useAxios";

const useCreateTransaction = (addState) => {
  const { walletProvider } = useVar();
  const { ownerAxios } = useAxios();

  const createInternalTransaction = useCallback(
    async (tx) => {
      try {
        const res = await ownerAxios.post("/createSignedTranscation", tx);

        await addState("success", res.data);
        await addState("success", "Closing in 5 seconds...");
      } catch (err) {
        console.log(err);
        await addState("error", err.response?.data ?? err.message);
        await addState("error", "Closing in 5 seconds...");
      }
    },
    [addState, ownerAxios]
  );

  const createTransactionByProvider = useCallback(
    async (tx) => {
      await addState("pending", "Waiting for Provider...");

      const TRADE_FEE = tx.asset.code === "sc" ? 1 : 1.03;

      tx.sentAmount = ((tx.amount + SC_FEE) * TRADE_FEE).toFixed(4);

      await ownerAxios.post("/createPendingTx", tx);

      try {
        await walletProvider.transferTo(tx.asset.name, tx.sentAmount);
      } catch (err) {
        console.log(err);
        await addState("error", err.message);
        await addState("error", "Closing in 5 seconds...");
      }
    },
    [addState, ownerAxios, walletProvider]
  );

  const createEvidnece = useCallback(
    async (formData) => {
      try {
        const res = await ownerAxios.post("/uploadFiles", formData);

        await addState("success", res.data);
        await addState("success", "Closing in 5 seconds...");
      } catch (err) {
        console.log(err);
        await addState("error", err.response?.data ?? err.message);
        await addState("error", "Closing in 5 seconds...");
      }
    },
    [addState, ownerAxios]
  );

  return { createInternalTransaction, createTransactionByProvider, createEvidnece };
};

export default useCreateTransaction;
