import { useState, useCallback } from "react";

const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false);

  const runLoading = useCallback(
    (callback) => {
      setIsLoading(true);

      setTimeout(() => {
        callback();
        setIsLoading(false);
      }, 1000);
    },
    [setIsLoading]
  );

  return [isLoading, setIsLoading, runLoading];
};

export default useLoading;
