import { ImSpinner2 } from "react-icons/im";
import { MdDone } from "react-icons/md";

const MultiButton = ({ state }) => {
  return (
    <>
      <button
        disabled={!(state.type === "action")}
        className={`
      py-2 w-full rounded-lg text-lg font-bold  transition-all duration-500
      
      ${
        state.type === "action" &&
        " bg-[var(--chakra-colors-blue-500)] hover:bg-[var(--chakra-colors-blue-300)] "
      }

      ${
        state.type === "success" &&
        " cursor-not-allowed bg-[var(--chakra-colors-green-600)] "
      }

      ${
        state.type === "pending" &&
        "  cursor-not-allowed bg-[var(--chakra-colors-blue-400)] "
      }
      ${
        state.type === "disabled" &&
        " opacity-40 cursor-not-allowed bg-[var(--chakra-colors-blue-400)] "
      }

      ${
        state.type === "error" &&
        " cursor-not-allowed bg-[var(--chakra-colors-red-500)] "
      }

      
      `}
      >
        <div className="flex space-x-2 justify-center items-center capitalize">
          {state.type === "pending" && (
            <ImSpinner2 className="w-5 h-5 animate-spin" />
          )}
          <div>{state.text}</div>

          {state.type === "success" && <MdDone className="w-5 h-5" />}
        </div>
      </button>

      {/* {state.second && (
        <button
          onClick={() => {
            if (state.type === "error") {
              modal.pop();
              return;
            }

            modal.close();
          }}
          className="w-full rounded-lg text-lg font-bold hover:bg-[var(--chakra-colors-gray-800)] py-2 capitalize"
        >
          {state.second}
        </button>
      )} */}
    </>
  );
};

export default MultiButton;
