import useVar from "../hooks/useVar";
import { useLocation, useNavigate } from "react-router-dom";

const LinkLoading = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { children, to, callback } = props;
  const { setIsLoading } = useVar();

  return (
    <button
      {...props}
      onClick={() => {
        if (location.pathname === to) return;
        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
          callback && callback();
          navigate(to);
        }, 1000);
      }}
    >
      {children}
    </button>
  );
};

export default LinkLoading;
