import { copyToClipboard } from "../js/utils";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useVar from "../hooks/useVar";
import { shortenAddress } from "../js/utils";
import { Assets, AssetIcon } from "../js/Assets";
import DetailedHistory from "../components/DetailedHistory";
import PreviewTranasction from "../modals/PreviewTranasction";
import DisputeEvidencesModal from "../modals/DisputeEvidencesModal";
import DisputeTextModal from "../modals/DisputeTextModal";

const History = () => {
  const { history } = useVar();

  if (history.length === 0) {
    return (
      <div className="min-h-full sm:p-4 bg-bg text-gray-200 ">
        <div className="p-5 text-xl sm:text-3xl ">No History</div>
      </div>
    );
  }
  return (
    <div className="min-h-full sm:p-4 bg-bg text-gray-200 ">
      <div className="p-5 text-xl sm:text-3xl ">Transaction History</div>

      {/* 
      <div className="sm:p-8 sm:flex justify-center">
        <div className="w-full sm:max-w-xl">
          <Trade />
        </div> */}

      <div className="mx-auto bg-[var(--chakra-colors-gray-785)] border border-gray-800 sm:rounded-lg shadow-md">
        {history.map(({ date, list }, index) => (
          <DateSection key={index} date={date} list={list} />
        ))}
      </div>
    </div>
  );
};

const DateSection = ({ list, date }) => {
  return (
    <section className={`sm:p-5 divide-y divide-gray-800`}>
      <div className="px-2 py-4 text-gray-600 font-bold">{date}</div>
      <div className="w-full text-sm text-gray-400">
        {list.map((item, index) => (
          <TxSection key={index} item={item} />
        ))}
      </div>
    </section>
  );
};

const ActionButton = ({ action, name, item }) => {
  const { modal, walletAddress } = useVar();

  // const { createTx } = useActionTx();
  return (
    <button
      onClick={() => {
        const tx = {
          initiator: walletAddress,
          dispute_type: "initiator of this dispute",
          amount: item.amount,
          name,
          recipient: item.address,
          payload: {
            action,
            hash: item.hash,
            description: item.description,
          },
        };

        if (name === "Dispute") {
          modal.pushWithLoading(
            "Dispute Evidences Form",
            <DisputeEvidencesModal tx={tx} />
          );
        } else if (name === "Fill Dispute Form...") {
          modal.pushWithLoading(
            "Dispute Form (1/11)",
            <DisputeTextModal step={0} tx={item} />
          );
        } else {
          modal.pushWithLoading(
            "Action required",
            <PreviewTranasction tx={tx} />
          );
        }
      }}
      className=" bg-indigo-600 text-indigo-200 hover:bg-indigo-500 py-1 px-2 sm:px-4 rounded-md  
                                         active:translate-y-2 text-xs sm:text-base
                                        hover:shadow-md duration-500"
    >
      {name}
    </button>
  );
};

const ChevronDownButton = ({ open, setOpen }) => {
  return (
    <button
      className=" h-6 w-6 bg-white bg-opacity-5 rounded-full p-1 "
      onClick={() => {
        setOpen(!open);
      }}
    >
      <ChevronDownIcon
        className={
          open
            ? "rotate-180 transition duration-500"
            : "rotate-0 transition duration-500 "
        }
      />
    </button>
  );
};

const TxSection = ({ item }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${
        open &&
        `bg-[var(--chakra-colors-gray-750)] rounded-lg border-opacity-20 border-gray-400 border
         divide-y divide-gray-400 divide-opacity-20`
      } 
       relative my-2 w-full font-semibold text-white hover:rounded-lg 
        hover:bg-[var(--chakra-colors-gray-750)] transition-opacity `}
    >
      <div className="flex justify-between items-center">
        <TxLine item={item} open={open} setOpen={setOpen} />
      </div>

      <DetailedHistory show={open} item={item} />
    </div>
  );
};

const TxLine = ({ item, open, setOpen }) => {
  return (
    <div className="py-4 w-full px-2 space-y-2">
      <div className="w-full flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-2 w-40 sm:w-80 ">
          {item.status.split(" ")[0] === "Disputed" ? (
            <div className="font-bold rounded-full bg-red-600 px-1 sm:py-2 sm:px-3.5 shadow-md">
              D
            </div>
          ) : (
            <div className="font-bold rounded-full bg-[var(--chakra-colors-whiteAlpha-200)] p-2 h-6 w-6 sm:h-10 sm:w-10 shadow-md">
              {item.icon}
            </div>
          )}

          <div className="text-xs sm:text-base space-y-2 ">
            <div className="">{item.title}</div>
            <div
              className={`${
                item.status.split(" ")[0] === "Disputed"
                  ? "text-red-500"
                  : "text-gray-500"
              } `}
            >
              {item.status}
            </div>
            <div className=" space-x-2 ">
              {item.buttons.map(({ action, name }, index) => (
                <ActionButton
                  key={index}
                  action={action}
                  name={name}
                  item={item}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="mx-auto text-xs sm:text-base">
          <div className="flex items-center space-x-4">
            <AssetIcon asset={Assets[3]} />

            <div>
              <div className="">{item.amount.toFixed(2)} USDT</div>
              <div className="text-gray-500 ">{item.amount.toFixed(2)} $</div>
            </div>
          </div>
        </div>{" "}
        <button
          onClick={() => {
            copyToClipboard(item.address);
          }}
          className="text-indigo-400  active:translate-y-2 duration-500 hidden sm:block"
        >
          {shortenAddress(item.address)}
        </button>
        <div className="text-base">{item.hours}</div>
        <ChevronDownButton open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};
export default History;
