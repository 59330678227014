import { useState, useEffect } from "react";

const useLocalStorage = (name) => {
  const [value, setValue] = useState(localStorage.getItem(name));

  useEffect(() => {
    console.log("useLocalStorage useEffect", name, value);

    value ? localStorage.setItem(name, value) : localStorage.removeItem(name);
  }, [value, name]);

  return [value, setValue];
};

export default useLocalStorage;
