import { useState, useMemo, useEffect, useCallback } from "react";
import useVar from "../hooks/useVar";
import MultiButton from "./buttons/MultiButton";
import SendDescription from "./SendDescription";

import { SC_FEE, shortenAddress } from "../js/utils";

const ModalSendAmount = ({ tx }) => {
  const symbols = [tx.asset.name, "USD"];

  const [input, setInput] = useState("");
  const [mode, setMode] = useState(0);
  const { exchangeRates, balances, modal } = useVar();
  const [buttonState, setButtonState] = useState({
    type: "disabled",
    text: "Please insert amount to send",
    second: "Cancel",
  });

  // const shortAddress = useMemo(() => {
  //   return tx.recipient.slice(0, 6) + "..." + tx.recipient.slice(62);
  // }, [tx.recipient]);

  const TRADE_FEE = useMemo(() => {
    return tx.asset.code === "sc" ? 1 : 1.03;
  }, [tx.asset.code]);

  const maxAmount = useMemo(() => {
    return Math.floor(
      Math.max(balances[tx.asset.code] / TRADE_FEE - SC_FEE, 0)
    );
  }, [tx.asset.code, balances, TRADE_FEE]);

  const minAmount = useMemo(() => {
    return (1 + SC_FEE) / TRADE_FEE;
  }, [TRADE_FEE]);

  const exchangeInput = useMemo(() => {
    return mode === 0
      ? (input * exchangeRates[tx.asset.code]).toFixed(2)
      : (input / exchangeRates[tx.asset.code]).toFixed(2);
  }, [exchangeRates, tx, input, mode]);

  const amount = useMemo(() => {
    return parseFloat(mode === 0 ? input : exchangeInput);
  }, [input, exchangeInput, mode]);

  const amountUSD = useMemo(() => {
    return parseFloat(mode === 0 ? exchangeInput : input);
  }, [input, exchangeInput, mode]);

  useEffect(() => {
    setButtonState({
      type: "action",
      text: "Next",
      second: "Cancel",
    });
  }, [input]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      tx.amount = amount;
      tx.amountUSD = amountUSD;
      tx.payload = {
        assetName: tx.asset.name,
        action: "REQUEST_TRANSFER",
        amount: amount * 10 ** 9,
      };

      modal.push("Send Safe " + tx.asset.name, <SendDescription tx={tx} />);
    },
    [amount, amountUSD, tx, modal]
  );

  return (
    <div className="space-y-6">
      {" "}
      <div className="space-y-2">
        <div className="flex items-center justify-between bg-gray-800 rounded-xl w-full py-1.5 px-3">
          <div className="text-sm font-bold text-[var(--chakra-colors-gray-500)] ">
            Destination Address
          </div>
          <div className="text-sm font-normal text-[var(--chakra-colors-gray-600)] ">
            {shortenAddress(tx.recipient)}
          </div>
        </div>{" "}
      </div>
      <form onSubmit={onSubmit} className="space-y-6">
        <div className="space-y-2">
          {/* <div className="text-base font-semibold text-[var(--chakra-colors-gray-500)] ">
          Send Input
        </div> */}

          <div className="flex items-center justify-between text-[var(--chakra-colors-gray-500)] text-sm font-medium">
            <div className="font-medium text-base"> Send Amount</div>
            <div
              className="cursor-pointer hover:text-[var(--chakra-colors-gray-300)] transition-all"
              onClick={() => {
                setInput(exchangeInput);
                setMode(mode ^ 1);
              }}
            >
              ≈ {exchangeInput} {symbols[mode ^ 1]}
            </div>
          </div>
          <div className="relative">
            <div
              onClick={() => {
                setInput(exchangeInput);
                setMode(mode ^ 1);
              }}
              className="absolute text-[var(--chakra-colors-gray-500)] z-20 left-2 top-1/2 -translate-y-1/2 cursor-pointer
                        hover:text-[var(--chakra-colors-gray-300)] px-2 py-1 hover:bg-[var(--chakra-colors-gray-750)] rounded-xl"
            >
              {symbols[mode]}
            </div>
            <input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              step="any"
              onWheel={(e) => e.target.blur()}
              autoFocus={true}
              className="text-xl pl-28 h-12 w-full rounded-xl opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                     border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2 transition-all appearance-none focus:outline-none focus:shadow-outline"
              id="amount"
              placeholder="Enter Amount"
              value={input}
              min={minAmount}
              max={maxAmount}
              onChange={(e) => setInput(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={() => {
                setInput(
                  (mode === 0
                    ? maxAmount
                    : maxAmount * exchangeRates[tx.asset.code]
                  ).toFixed(2)
                );
              }}
              className="absolute text-[var(--chakra-colors-blue-300)] z-20 right-2 top-1/2 -translate-y-1/2 cursor-pointer
                        px-2 py-1 hover:bg-indigo-700 hover:bg-opacity-5 rounded-xl transition-all"
            >
              Max
            </button>
          </div>
        </div>

        <div className="space-y-2">
          <MultiButton state={buttonState} />
        </div>
      </form>
    </div>
  );
};

export default ModalSendAmount;
