import socketIOClient from "socket.io-client";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

const useSocketIo = (secret, setIsLoading) => {
  const [walletAddress, setWalletAddress] = useState();
  const [receivedMessage, setReceivedMessage] = useState();
  const [account, setAccount] = useState({});
  const [inbox, setInbox] = useState([]);
  const [ruling, setRuling] = useState([]);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SERVER);
    socket.on("connect", () => {
      console.log(`useSocketIo is connected.`, socket.id);

      const fcmToken = localStorage.getItem("fcmToken");
      socket.emit("onUserReactConnected", { secret, fcmToken });
    });
    socket.on("reconnect", (attemptNumber) => {
      console.log(`useSocketIo Reconnected after ${attemptNumber} attempts`);
    });

    socket.on("reconnect_error", (error) => {
      console.log("useSocketIo Reconnection error:", error);
    });
    socket.on("onAccountRefresh", ({ account, ruling }) => {
      console.log(`useSocketIo::onAccountRefresh`);
      setAccount(account);
      setRuling(ruling);
    });

    socket.on("onWalletAddress", (walletAddress) => {
      console.log(`useSocketIo::onWalletAddress`);
      setWalletAddress(walletAddress);
    });

    socket.on("onReceivedMessage", (state) => {
      console.log(`useSocketIo::onReceivedMessage`, state.text);
      setReceivedMessage(state);
    });

    socket.on("onInbox", (inbox) => {
      console.log(`useSocketIo::onInbox`);
      setInbox(inbox);
      setIsLoading(false);
    });
    socket.on("onToast", (msg) => {
      console.log(`useSocketIo::onToast`);
      toast.success(msg + ". Go to Transactions History to see details.");
    });

    socket.on("disconnect", () => {
      console.log(`useSocketIo disconnected . Waiting...`);
    });
    // Cleanup function to disconnect the socket when the component unmounts
    return () => {
      console.log(`useSocketIo is disconnected permanent.`);
      socket.disconnect();
    };
  }, [secret, setIsLoading]);

  return {
    receivedMessage,
    setReceivedMessage,
    account,
    inbox,
    walletAddress,
    ruling,
  };
};

export default useSocketIo;
