import { CodeBracketIcon } from "@heroicons/react/20/solid";
import logo from "../assets/coins/sc_bw.svg";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const features = [
  {
    name: "Put the following HTML code in your page between the <body> tag:",
    description: (
      <SyntaxHighlighter language="html" style={vscDarkPlus}>
        {`<button id="SafeCoinButton">Pay By SafeCoin</button>`}
      </SyntaxHighlighter>
    ),
    icon: CodeBracketIcon,
  },
  {
    name: "Put the following code to your javascript:",
    description: (
      <SyntaxHighlighter
        style={vscDarkPlus}
        showLineNumbers={true}
        language="javascript"
      >
        {`<script type="text/javascript">
    document.getElementById("SafeCoinButton").addEventListener("click", function () {
      
      const payload = {
        amount: 199.95, // amount of the order
        wallet: "0x", // your wallet address
        name: "eBay", // name of Marchant
        description: "Nike Air Max", // description of the order
          
      };
      const width = 500;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      window.open(
        "https://app.safecoin.ai/pay/" + encodeURIComponent(JSON.stringify(payload)),
        "Pay By SafeCoin",
        "height=" + height + ",width=" + width + ",left=" + left + ",top=" + top
      );
    });
</script>`}
      </SyntaxHighlighter>
    ),
    icon: CodeBracketIcon,
  },
];

function Hub() {
  return (
    <div className="min-h-full sm:p-4  bg-bg text-gray-200 ">
      <div className="overflow-hidden text-slate-100 py-4">
        <div className="mx-auto px-6 lg:px-8 lg:pr-8 lg:pt-4">
          <div className="">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-400">
              Start to use SC payments solution in your website right now!
            </h2>

            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
              Following this steps:
            </p>
            {/* <p className="mt-6 text-lg leading-8 text-gray-400">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </p> */}
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-100">
                    <feature.icon
                      className="absolute top-1 left-1 h-5 w-5 text-indigo-400"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>{" "}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
            {/* <p className="mt-10 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
              Demo:
            </p> */}
            <button
              type="submit"
              onClick={() => {
                const payload = {
                  amount: 199.95,
                  wallet: "0x",
                  name: "eBay",
                  description:
                    "Nike Air Max Command Mens US Size 7-13 Black/White Running Casual Shoes NEW",
                };
                const width = 500;
                const height = 600;
                const left = (window.innerWidth - width) / 2;
                const top = (window.innerHeight - height) / 2;

                window.open(
                  `/pay/${encodeURIComponent(JSON.stringify(payload))}`,
                  "Pay By SafeCoin",
                  `height=${height},width=${width},left=${left},top=${top}`
                );
              }}
              className=" px-4 group mt-12 rounded-lg bg-slate-700 py-2 hover:bg-slate-600
     active:translate-y-1 duration-500 shadow-lg flex justify-center items-center "
            >
              <img
                alt=""
                src={logo}
                className="mr-2 w-6 h-6 text-indigo-400 hover:text-indigo-300"
              />
              Pay with SafeCoin
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hub;
