import useVar from "../hooks/useVar";
import { Assets, AssetIcon } from "../js/Assets";

const AssetSelection = ({ setAsset }) => {
  const { modal } = useVar();

  return (
    <>
      {Assets.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            setAsset(item);
            modal.close();
          }}
          className="hover:bg-[var(--chakra-colors-gray-750)] text-gray-100
      rounded-xl w-full py-2 px-6 text-left"
        >
          <div className="flex space-x-6 items-center justify-start">
            <AssetIcon asset={item}/>

            <div>
              <div className="text-base font-semibold text-[var(--chakra-colors-gray-500)] ">
                {item.name} On{" "}
              </div>
              <div className="text-sm font-normal text-[var(--chakra-colors-whiteAlpha-500)]">
                {item.chain.toUpperCase()}
              </div>
            </div>
          </div>
        </button>
      ))}
    </>
  );
};

export default AssetSelection;
