import { Tooltip } from "flowbite-react";
import useNetWorth from "../hooks/useNetWorth";

const content = (
  <>
    <p className="mb-3 text-sm  text-indigo-200">
      Credits allows you to support the network of SafeCoin. <br />
      You will make actual SafeCoin at the value of $1 from the fees on the
      network.
    </p>
    <a
      className="text-sm  underline dark:text-[var(--chakra-colors-blue-200)] dark:hover:text-[var(--chakra-colors-blue-200)]"
      href="http://app.safecoin.ai/mining"
    >
      Learn more Here
    </a>
  </>
);

const svgWallet = (
  <svg viewBox="0 0 24 23" className="w-8 h-8 text-blue-600" focusable="false">
    <g fill="currentColor" fillRule="nonzero">
      <g>
        <path
          d="M16.051 0.116127C18.1998 -0.456855 20.3077 1.16268 20.3077 3.38646V4.1825H20.6154C22.4847 4.1825 24 5.69785 24 7.56712V18.644C24 20.5133 22.4847 22.0287 20.6154 22.0287H3.38462C1.51535 22.0287 0 20.5134 0 18.644V6.99673C0 5.46333 1.0309 4.1215 2.51252 3.72639L16.051 0.116127ZM7.96713 4.1825L16.5267 1.89995C17.5034 1.6395 18.4615 2.37564 18.4615 3.38646V4.1825H7.96713ZM1.84615 7.56712V18.644C1.84615 19.4937 2.53494 20.1825 3.38462 20.1825H20.6154C21.4651 20.1825 22.1538 19.4937 22.1538 18.644V7.56712C22.1538 6.71744 21.4651 6.02865 20.6154 6.02865H3.38462C2.53495 6.02865 1.84615 6.71745 1.84615 7.56712ZM17.5385 13.4133C17.7084 13.4133 17.8462 13.2756 17.8462 13.1056C17.8462 12.9356 17.7084 12.7979 17.5385 12.7979C17.3685 12.7979 17.2308 12.9356 17.2308 13.1056C17.2308 13.2756 17.3685 13.4133 17.5385 13.4133ZM16 13.1056C16 12.256 16.6888 11.5671 17.5385 11.5671C18.3881 11.5671 19.0769 12.256 19.0769 13.1056C19.0769 13.9552 18.3881 14.644 17.5385 14.644C16.6888 14.644 16 13.9552 16 13.1056Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

const svgSC = (
  <svg
    viewBox="0 0 24 24"
    focusable="false"
    className="w-8 h-8 text-purple-600"
  >
    <g clipPath="url(#clip0_25_654)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3467 13.8434C16.8135 13.6394 17.3574 13.8525 17.5614 14.3193C18.2584 15.9145 18.4299 17.69 18.0513 19.389C17.6726 21.0881 16.7633 22.6227 15.4549 23.7709C15.0894 24.0915 14.5379 24.0736 14.1941 23.7298L10.7254 20.2611C10.3651 19.9009 10.3651 19.3168 10.7254 18.9565C11.0857 18.5962 11.6698 18.5962 12.0301 18.9565L14.8461 21.7726C15.5352 20.9786 16.0198 20.0225 16.2504 18.9877C16.5439 17.6707 16.4109 16.2945 15.8707 15.0581C15.6667 14.5912 15.8798 14.0474 16.3467 13.8434Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61097 5.94874C6.31005 5.57006 8.08553 5.74162 9.68066 6.43862C10.1475 6.64262 10.3606 7.18647 10.1566 7.65334C9.95263 8.1202 9.40878 8.3333 8.94192 8.1293C7.70551 7.58905 6.32931 7.45607 5.01234 7.74959C3.9775 7.98023 3.02138 8.46481 2.22744 9.15388L5.04351 11.9699C5.40378 12.3302 5.40378 12.9143 5.04351 13.2746C4.68324 13.6349 4.09914 13.6349 3.73887 13.2746L0.270202 9.80592C-0.0735815 9.46214 -0.0915507 8.91055 0.229129 8.54512C1.37731 7.23671 2.9119 6.32742 4.61097 5.94874Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5071 0.0254028C21.8432 -0.0266898 22.1868 0.00158717 22.5099 0.107917C22.8329 0.214248 23.1261 0.395606 23.3656 0.637127C23.605 0.878648 23.7838 1.17346 23.8873 1.49741C23.9906 1.82071 24.016 2.16385 23.9614 2.49881C23.4101 5.96028 20.9815 13.801 10.9235 21.1056C10.5564 21.3722 10.0499 21.3323 9.72909 21.0114L9.64339 20.9257L8.57368 21.9955C8.50842 22.0607 8.43438 22.1152 8.35435 22.1577C7.97578 22.4578 7.50504 22.6233 7.01729 22.6233C6.44862 22.6233 5.90306 22.3983 5.49975 21.9974L5.49781 21.9955L2.00454 18.5022L2.0026 18.5003C1.60169 18.0969 1.37666 17.5514 1.37666 16.9827C1.37666 16.414 1.60169 15.8685 2.0026 15.4652L2.00454 15.4632L3.0927 14.3751L2.98856 14.2709C2.66872 13.9511 2.62795 13.4465 2.89227 13.0795C10.1777 2.96288 18.0853 0.589109 21.4985 0.0267867L21.5071 0.0254028ZM10.4722 19.1445C10.4308 19.0708 10.3791 19.0025 10.318 18.9419L5.05351 13.7143C5.02369 13.6847 4.99235 13.6575 4.95974 13.6328L4.85296 13.526C11.6712 4.40676 18.8482 2.33447 21.7927 1.84822C21.8398 1.84139 21.8878 1.84557 21.933 1.86045C21.9791 1.87564 22.021 1.90155 22.0552 1.93606C22.0894 1.97056 22.115 2.01268 22.1298 2.05895C22.1446 2.10523 22.1482 2.15436 22.1403 2.2023L22.1396 2.20662C21.6677 5.17316 19.5567 12.2986 10.4722 19.1445ZM4.39734 15.6797L3.31111 16.7659C3.31093 16.7661 3.3113 16.7657 3.31111 16.7659C3.25419 16.8235 3.22169 16.9017 3.22169 16.9827C3.22169 17.0637 3.25363 17.1414 3.31056 17.1989C3.31074 17.1991 3.31038 17.1988 3.31056 17.1989L6.8005 20.6889C6.80071 20.6891 6.80029 20.6887 6.8005 20.6889C6.85805 20.7458 6.93635 20.7783 7.01729 20.7783C7.09853 20.7783 7.17647 20.7462 7.23408 20.6889C7.28592 20.6374 7.34323 20.5926 7.40465 20.5552L8.33876 19.6211L4.39734 15.6797Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

const svgMC = (
  <svg viewBox="0 0 32 28" focusable="false" className="w-8 h-8 text-green-400">
    <g fill="currentColor" fillRule="nonzero">
      <path
        d="M2.0376 26C2.0376 27.1063 2.93135 28 4.0376 28H14.0376V18H2.0376V26ZM18.0376 28H28.0376C29.1438 28 30.0376 27.1063 30.0376 26V18H18.0376V28ZM30.0376 8H27.4063C27.7938 7.24375 28.0376 6.40625 28.0376 5.5C28.0376 2.46875 25.5688 0 22.5376 0C19.9376 0 18.2563 1.33125 16.1001 4.26875C13.9438 1.33125 12.2626 0 9.6626 0C6.63135 0 4.1626 2.46875 4.1626 5.5C4.1626 6.40625 4.4001 7.24375 4.79385 8H2.0376C0.931348 8 0.0375977 8.89375 0.0375977 10V15C0.0375977 15.55 0.487598 16 1.0376 16H31.0376C31.5876 16 32.0376 15.55 32.0376 15V10C32.0376 8.89375 31.1438 8 30.0376 8ZM9.65635 8C8.2751 8 7.15635 6.88125 7.15635 5.5C7.15635 4.11875 8.2751 3 9.65635 3C10.9001 3 11.8188 3.20625 15.0376 8H9.65635ZM22.5376 8H17.1563C20.3688 3.21875 21.2626 3 22.5376 3C23.9188 3 25.0376 4.11875 25.0376 5.5C25.0376 6.88125 23.9188 8 22.5376 8Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);
const BalanceBox = ({ name, svg, amount, content }) => {
  return (
    <div className="w-full mb-2 sm:mb-0">
      <div className="h-full w-full py-4 px-10 flex sm:flex-row items-center space-x-4 max-w-[250px]">
        {svg}
        <div className="	">
          <div className=" text-[var(--chakra-colors-gray-500)] text-sm font-medium text-left overflow-hidden">
            {name}
          </div>
          <Tooltip
            animation="duration-1000"
            content={content ?? amount}
            placement="bottom"
          >
            <div className="text-gray-200 text-2xl flex items-center  cursor-default">
              $ {amount}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const NewBalances = () => {
  const { netWorth } = useNetWorth();

  return (
    <div className="w-full pt-4 sm:grid grid-flow-col sm:grid-cols-3 gap-4">
      <div className="">
        <BalanceBox
          name="Wallet Balance"
          amount={netWorth}
          svg={svgWallet}
        />
      </div>
      <div className="">
        <BalanceBox name="Earn Balance" amount={(0).toFixed(2)} svg={svgSC} />
      </div>
      <div className="">
        <BalanceBox
          content={content}
          name="Credits Balance"
          amount={0}
          svg={svgMC}
        />{" "}
      </div>
    </div>
  );
};

export default NewBalances;
