import { toast } from "react-hot-toast";

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const CopyToClipboardButton = ({ children, address }) => {
  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(address);
      }}
      className="active:translate-y-2 duration-500 hover:scale-105  "
    >
      {children}
    </button>
  );
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const camelize = (str) => {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const shortenAddress = (add) => {
  if (!add) return "";
  return add.slice(0, 6) + "..." + add.slice(62);
};

export const VERIFY = (con, text) => {
  if (!con) {
    throw new Error(text);
  }
};

export const isAdmin = (walletAddress) => {
  return (
    walletAddress ===
      "0x602f3f043ffdfccaca1548e8064346c8bc7a483979cb6a9ff4a267b2215b05f8" ||
    walletAddress ===
      "0x8f46daefbe4c08ed0710d4c16d6ffade342c88abad50eace324d0eb908ebc4c1"
  );
};

export const isAmir = (walletAddress) => {
  return (
    walletAddress ===
    "0x602f3f043ffdfccaca1548e8064346c8bc7a483979cb6a9ff4a267b2215b05f8"
  );
};

export const SC_FEE = 3 * 0.63;

export const string2uuid = (str) => {
  const pattern = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
  const matches = str.match(pattern);

  if (matches) {
    const [, part1, part2, part3, part4, part5] = matches;
    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  // Return the original string if it doesn't match the pattern
  return str;
};

export const post = async (action, body) => {
  const url = process.env.REACT_APP_SERVER + action;

  const config = {
    method: "POST",
    headers: {
      mode: "cors",
      credentials: "include",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, config);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    toast.error("Error: " + err);
  }
};

export const get = async (action) => {
  const url = process.env.REACT_APP_SERVER + action;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    toast.error("Error: " + err);
  }
};
