import icons from "../js/icons";
import { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";

const QrReader = ({ setCode }) => {
  const [isRecording, setIsRecording] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setIsRecording(!isRecording);
        }}
        className="absolute text-[var(--chakra-colors-blue-300)] z-20 right-2 top-1/2 -translate-y-1/2 cursor-pointer
                    p-2 hover:bg-indigo-700 hover:bg-opacity-20 rounded-xl transition-all w-8 h-8"
      >
        {!isRecording ? icons.qr : icons.cross}
      </div>
      {isRecording && (
        <div className="">
          {/* //fixed inset-0 z-50 */}
          <QrScanner
            onDecode={(result) => {
              console.log(result);
              setCode(result.trim());
              setIsRecording(false);
            }}
            onError={(error) => console.log(error?.message)}
          />
        </div>
      )}
    </>
  );
};

export default QrReader;
