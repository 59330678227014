import { useCallback } from "react";
import useVar from "../hooks/useVar";
import useAxios from "./useAxios";

const useTrade = () => {
  const { signature, walletProvider, modal } = useVar();
  const { myAxios } = useAxios();

  const tradeBuySC = useCallback(
    async (amountPay, name) => {
      console.log("tradeBuySC");

      try {
        if (!walletProvider.signer) throw new Error("MetaMask is not connected");

        await modal.addState("pending", "Waiting for MetaMask...");

        const TRADE_FEE = 1.03;
        const sentAmount = (amountPay * TRADE_FEE).toFixed(4);

        await myAxios.post("/createPendingTx", {
          signature,
          sentAmount,
        });
        await walletProvider.transferTo(name, sentAmount);
      } catch (err) {
        console.error(err);

        await modal.addState("error", err.response?.data ?? err.message);
        await modal.addState("error", "Closing in 5 seconds...");
      }
    },
    [myAxios, signature, modal, walletProvider]
  );

  const tradeSellSC = useCallback(
    async (amountPay, name) => {
      console.log("tradeSellSC");

      try {
        await myAxios.post("/sellSC", {
          asset: name,
          amount: amountPay,
          signature,
        });
      } catch (err) {
        console.log(err);
        await modal.addState("error", err.response.data);
        await modal.addState("error", "Closing in 5 seconds...");
      }
    },
    [myAxios, signature, modal]
  );

  return { tradeBuySC, tradeSellSC };
};

export default useTrade;
