import useVar from "../hooks/useVar";
import MultiButton from "./buttons/MultiButton";
import TradeComponent from "../components/TradeComponent";
import useTrade from "../hooks/useTrade";

const PreviewTrade = ({ trade }) => {
  const { amountPay, setAmountPay, from, to } = trade;
  const { receivedMessage, modal } = useVar();

  const { tradeBuySC, tradeSellSC } = useTrade();

  const onSubmit = async (e) => {
    e.preventDefault();

    await modal.addState("pending", "Connecting to Blockchain...");
    await modal.addState("pending", "Connected.");

    setAmountPay("");
    to.code === "sc"
      ? tradeBuySC(amountPay, from.name)
      : tradeSellSC(amountPay, to.name);
  };

  return (
    <div className="space-y-14">
      {/* <div className="space-y-2">
        <div className="flex items-center justify-between bg-gray-800 rounded-xl w-full py-1.5 px-3">
          <div className="text-sm font-bold text-[var(--chakra-colors-gray-500)] ">
            Destination Address
          </div>
          <div className="text-sm font-normal text-[var(--chakra-colors-gray-600)] ">
            {shortenAddress(tx.recipient)}
          </div>
        </div>{" "}
      </div> */}
      {/* <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Transaction Fee
        </div>
      </div> */}
      <div className="space-y-2">
        <div className="text-4xl font-bold text-blue-200  text-center">
          Are you sure you want to make this trade?
        </div>
      </div>
      <div className="space-y-2">
        <TradeComponent from={from} to={to} amountPay={amountPay} />
      </div>

      <form onSubmit={onSubmit} className="space-y-4">
        <div className="space-y-2">
          <MultiButton state={receivedMessage} />
        </div>
      </form>
    </div>
  );
};

export default PreviewTrade;
