import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import ScImg from "../assets/coins/sc_bw.svg";
import useVar from "../hooks/useVar";

export default function ModalComp() {
  const { isLoading } = useVar();

  return (
    <Transition.Root show={isLoading} as={Fragment}>
      <div className="relative z-50">
        <Transition.Child
          // as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0 "
        >
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity ">
            <div className="flex justify-center items-center h-screen">
              <Transition.Child
                // as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 "
                leaveTo="opacity-0 scale-0"
              >
                <img src={ScImg} alt="sc" className="animate-spin h-14 mr-2 " />
              </Transition.Child>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
}
