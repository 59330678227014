import MainSidebar from "./Main/MainSidebar";
import Filter from "./Main/Filter";
import MailBody from "./Main/MailBody";
import { useState } from "react";
import useVar from "../../hooks/useVar";

export default function Inbox() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { inbox } = useVar();

  if (inbox.length === 0) {
    return (
      <div className="min-h-full sm:p-4 bg-bg text-gray-200 ">
        <div className="p-5 text-xl sm:text-3xl ">No Messages</div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex overflow-hidden ">
        <div className="xs:flex bg-[var(--chakra-colors-gray-800)] flex-1">
          <div className="xs:flex-grow xs:w-full xs:max-w-xs bg-[var(--chakra-colors-gray-800)] border-x border-b border-[var(--chakra-colors-gray-600)] flex flex-col">
            <Filter />
            <div className="overflow-y-auto">
              {inbox.map((msg, index) => {
                return (
                  <MainSidebar
                    key={index}
                    index={index}
                    msg={msg}
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                  />
                );
              })}
            </div>
          </div>
          <div className="max-xs:hidden xs:flex-1 xs:w-0 flex-col flex">
            <div className=" overflow-y-auto flex-1">
              <MailBody msg={inbox[activeIndex]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
