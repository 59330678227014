import { useCallback } from "react";

import useVar from "../hooks/useVar";
import ScWalletMenu from "../modals/ScWalletMenu";

const useSelfCustody = (wallet) => {
  const { walletProvider, modal } = useVar();
  const { setProviderType } = walletProvider;

  const connect = useCallback(async () => {
    localStorage.setItem("SSN", wallet.private_key);
    setProviderType("Self");

    modal.close();
  }, [wallet, modal, setProviderType]);

  const onClick = useCallback(async () => {
    modal.pushWithLoading("SafeCoin Wallet", <ScWalletMenu />);
  }, [modal]);

  return { onClick, connect };
};

export default useSelfCustody;
