import { useCallback, useEffect, useRef, useState } from "react";
import {
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
  query,
  doc,
  getDoc,
  // getDocs,
  // where,
  // updateDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../js/firebase";

const useFcm = () => {
  const [tokens, setTokens] = useState([]);

  const tokensCollectionRef = useRef(collection(db, "tokensReact"));

  const addToken = useCallback(async (wallet, token) => {
    console.log(token);

    if (!wallet) return;
    if (!token) return;

    const docRef = doc(db, "tokensReact", wallet);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return await updateDoc(docRef, { wallet, token });
    }

    await addDoc(tokensCollectionRef.current, {
      wallet,
      token,
      createdAt: serverTimestamp(),
    });
  }, []);

  const getTokens = useCallback(async () => {
    try {
      const queryTransactions = query(
        tokensCollectionRef.current,
        // where("userID", "==", userID),
        orderBy("createdAt")
      );

      const unsubscribe = onSnapshot(queryTransactions, (snapshot) => {
        let docs = [];

        snapshot.forEach((doc) => {
          const data = doc.data();
          const id = doc.id;

          docs.push({ ...data, id });
        });

        setTokens(docs);

        console.log(docs);
      });
      return () => unsubscribe();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  return { tokens, addToken };
};

export default useFcm;
