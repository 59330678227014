import { useState, useEffect, useCallback } from "react";
import useAxios from "./useAxios";
import useVar from "../hooks/useVar";

const useFetchSignature = (url) => {
  const [data, setData] = useState([]);
  const { myAxios } = useAxios();
  const { signature } = useVar();

  const updateData = useCallback(async () => {
    if (!signature) return;

    try {
      const res = await myAxios.post(url, { signature });
      console.log(`useFetchSignature: ${url}`, res.data);
      setData(res.data);
    } catch (error) {
      console.error(error, url);
    }
  }, [url, myAxios, signature]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [data];
};

export default useFetchSignature;
