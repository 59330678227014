import { useState } from "react";
import BuyTab from "../components/BuyTab";
import SellTab from "../components/SellTab";

const Exchange = () => {
  const [tab, setTab] = useState(0);

  return (
    <div className="min-h-full p-5 bg-bg bg-bgMain bg-cover bg-bottom text-gray-200 flex flex-wrap items-center justify-around">
      <div className=" max-w-lg leading-4 text-center md:text-left w-full">
        <h2 className="font-bold text-6xl mb-5 ">
          Buy Crypto
          <span className="ml-4 text-gradient ">in Seconds</span>
        </h2>
        <p className="mb-5 text-xl">
          Use a credit card, debit card or bank account. Buy Bitcoin, Ethereum,
          USDT, USDC or other top cryptocurrencies.
        </p>
        <p className="text-sm text-gray-500">
          {/* *You can also sell crypto directly for cash into your bank account. */}
        </p>
      </div>

      <div className="border border-[var(--chakra-colors-gray-700)] min-w-xs max-w-xs rounded-lg bg-gray-800 shadow-sm w-full ">
        <div
          className="flex text-sm font-medium text-center underline-offset-4"
        >
          {["Buy", "Sell"].map((name, index) => (
            <div
              key={index}
              className={
                `w-full cursor-pointer p-4  duration-500 transition hover:scale-125 text-lg` +
                (tab === index
                  ? ` font-bold  text-gray-300 underline  `
                  : `   text-gray-400 bg-opacity-20 bg-black  `)
              }
              onClick={() => setTab(index)}
            >
              {name}
            </div>
          ))}
        </div>
        {tab === 0 ? <BuyTab /> : <SellTab />}
      </div>
    </div>
  );
};

export default Exchange;
