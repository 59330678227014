import { useMemo } from "react";
import useVar from "../hooks/useVar";

const useNetWorth = () => {
  const { balances, exchangeRates } = useVar();

  const netWorth = useMemo(() => {
    let sum = 0;
    for (const key in balances) {
      sum += balances[key] * exchangeRates[key];
    }

    return sum.toFixed(2);
  }, [balances, exchangeRates]);

  return { netWorth };
};

export default useNetWorth;
