import { useState, useEffect ,useCallback} from "react";
import useAxios from "./useAxios";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const { myAxios } = useAxios();

  const updateData = useCallback(async () => {

    try {
      const res = await myAxios.post(url, {  });
      console.log(`useFetch: ${url}`, res.data);
      setData(res.data);
    } catch (error) {
      console.error(error, url);
    }
  }, [url, myAxios ]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [data];
};

export default useFetch;
