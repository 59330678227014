import useVar from "../hooks/useVar";
import ScWalletCreate from "./ScWalletCreate";
import ScWalletImportMenu from "./ScWalletImportMenu";

const Button = ({ onClick, text }) => {
  return (
    <button
      onClick={() => {
        onClick();
      }}
      className={`pl-4 flex justify-between text-base text-gray-100 w-full rounded-lg font-semibold  bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-700)] py-2 `}
    >
      <div className="">{text}</div>
      {/* <div className="pr-4">-></div> */}
    </button>
  );
};

const ScWalletMenu = () => {
  const { modal } = useVar();

  return (
    <div className="md:p-4 space-y-10 max-md">
      <div className="space-y-2 space-x-4">
        <div className="text-base text-gray-400">
          You can have multiple SafeCoin wallets. You may load a wallet, import
          a wallet from a Secret Recovery Phrase, or create a new wallet.
        </div>
      </div>

      <div className="space-y-2">
        <Button
          text={"Create a New Wallet"}
          onClick={() => {
            modal.push("Your Secret Recovery Phrase", <ScWalletCreate />);
          }}
        />

        <Button
          text={"Import a Wallet From"}
          onClick={() => {
            modal.push("Import Wallet", <ScWalletImportMenu />);
          }}
        />
      </div>
    </div>
  );
};

export default ScWalletMenu;
