// import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import LinkLoading from "./LinkLoading";
import logo from "../assets/coins/sc_bw.svg";
import SideBar from "./SideBar";

const SideBarMobile = () => {
  return (
    <div className="fixed top-0 left-0 -z-20 w-80 h-screen pt-16">
      <SideBar mobile={true} />;
    </div>
  );
};

const ThreeIcon = (
  <svg
    className="w-6 h-6"
    aria-hidden="true"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
    ></path>
  </svg>
);

const HeaderLogo = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    // console.log("Location changed:", location);

    setShow(false);
  }, [location]);

  return (
    <div className="flex items-center justify-start">
      <button
        onClick={() => setShow(!show)}
        type="button"
        className="md:hidden inline-flex items-center p-2 text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        {ThreeIcon}
      </button>
      {show && <SideBarMobile onClick={() => setShow(false)} />}
      <LinkLoading to="/Dashboard" className="hidden sm:flex  ">
        <img src={logo} className="h-10 mr-2" alt=" Logo" />
        {/* <span
      className=" tracking-normal  self-center text-xl font-bold sm:text-3xl 
    whitespace-nowrap logo-gradient"
    >
      SafeCoin
    </span>
    <span className="text-gradient">{networkName}</span> */}
      </LinkLoading>
    </div>
  );
};

export default HeaderLogo;
