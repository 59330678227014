import { useState } from "react";
import useVar from "../hooks/useVar";
import CancelButton from "./buttons/CancelButton";
import NextButton from "./buttons/NextButton";
import { ethers } from "ethers";
import ScWalletCreateDone from "./ScWalletCreateDone";

const ScWalletImportPrivateKey = ({ tx }) => {
  const [key, setKey] = useState("");
  const { modal } = useVar();

  return (
    
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Type or paste your Private Key:
           {/* Ex: */}
          {/* 0x82632c5165bfd2fc345ff065ee5187e71f749c3aefa03ce304b5136bab689d67 */}
        </div>
        <div className="h-10">
          <textarea
            type="text"
            id="key"
            autoFocus={true}
            className="h-full w-full rounded-xl text-sm opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            minLength={66}
            maxLength={66}
            rows={1}
            value={key}
            onChange={(e) => setKey(e.target.value)}
            required
          />
        </div>

        <div className="text-xs text-gray-600 mb-5 text-right">
          ({key.length}/1000)
        </div>
      </div>
      <div className="space-y-2">
        <NextButton
          text={{ disabled: "Please insert a key", enabled: "Next" }}
          disabled={key.length < 66}
          onClick={() => {
            const userWallet = new ethers.Wallet(
              "0x82632c5165bfd2fc345ff065ee5187e71f749c3aefa03ce304b5136bab689d67"
            );

            const wallet = {
              signMessage: userWallet.signMessage.bind(userWallet),
              private_key: userWallet.privateKey.toLowerCase(),
              public_key: userWallet.address.toLowerCase(),
              // key: userWallet.mnemonic.phrase.split(" "),
            };

            modal.push(
              "Your Wallet is Self-Custody",
              <ScWalletCreateDone wallet={wallet} />
            );
          }}
        />
        <CancelButton />
      </div>
    </div>
  );
};

export default ScWalletImportPrivateKey;
