import { useLocation, Outlet, Navigate } from "react-router-dom";
import useVar from "../hooks/useVar";
import useFcm from "../hooks/useFcm";

import Header from "./Header";
import SideBar from "./SideBar";
import BottomBar from "./BottomBar";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const RequireAuth = () => {
  const { signature, walletAddress, showBottomBar } = useVar();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const { addToken } = useFcm();

  useEffect(() => {
    const fcmToken = searchParams.get("fcmToken");

    if (fcmToken) {
      localStorage.setItem("fcmToken", fcmToken);

      console.log("fcmToken", fcmToken);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!walletAddress) return;

    const fcmToken = localStorage.getItem("fcmToken");
    fcmToken && addToken(walletAddress, fcmToken);

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "yiuhhqdu",
      // user_hash: walletAddress,
      user_id: walletAddress,
      name: walletAddress,
    });

    return () => {
      window.Intercom("shutdown");
    };
  }, [walletAddress, addToken]);

  return signature ? (
    <div className="">
      <Header />
      <div className="hidden md:block fixed top-0 left-0 h-screen pt-[72px] md:w-28 lg:w-80  ">
        <SideBar />
      </div>
      <div className="flex flex-col h-screen pt-[72px] md:ml-28 lg:ml-80  max-md:pt-[114px]">
        <div className="flex-grow max-md:pb-20 max-sm:pb-[92px]">
          <Outlet />
        </div>
        {showBottomBar && <BottomBar />}
      </div>
    </div>
  ) : (
    <Navigate to="/Splash" state={{ from: location }} replace />
  );
};
export default RequireAuth;
