import useVar from "../hooks/useVar";
import { CiWarning } from "react-icons/ci";

export default function AttentionModal() {
  const { modal } = useVar();

  return (
    <div className="space-y-6">
      <div className="flex space-x-2 text-base font-thin text-yellow-200 ">
        <div className="">
          <CiWarning size={58} />
        </div>
        <div className="">
          We noticed you have some Dispute incidents open on your SafeCoin
          account. The SafeCoin AI will take care the all process, Please go to
          the Tranaction History page and ensure you have fulfilled the Dispute
          Form.
          <br />
          If you already did so, please ignore this message.
        </div>
      </div>
      <div className="space-y-2">
        <button
          onClick={modal.pop}
          className="w-full rounded-lg text-lg font-bold hover:bg-[var(--chakra-colors-gray-800)] py-2 "
        >
          Close
        </button>
      </div>
    </div>
  );
}
