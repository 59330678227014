import { useEffect, useCallback } from "react";

import AttentionModal from "../modals/AttentionModal";
import { post } from "../js/utils";
import useVar from "../hooks/useVar";

const useAttentionModal = () => {
  const { modal, walletAddress } = useVar();

  const updateData = useCallback(async () => {
    try {
      if (!modal || !walletAddress) return;

      const show = await post("/isActionReqired", { walletAddress });
      show && modal.push("Attention required", <AttentionModal />);
    } catch (error) {
      console.error(error);
    }
  }, [modal, walletAddress]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return {};
};

export default useAttentionModal;
