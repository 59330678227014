import useVar from "../hooks/useVar";
import { AssetIcon } from "../js/Assets";
import { MdEast } from "react-icons/md";
import AssetSelection from "../modals/AssetSelection";

const AccountBlock = ({ name, asset, setAsset, amount }) => {
  const { metaMask, modal } = useVar();

  return (
    <button
      disabled={amount}
      onClick={() =>
        modal.push("Trade " + name, <AssetSelection setAsset={setAsset} />)
      }
      className="text-left w-full bg-[var(--chakra-colors-gray-800)] 
              rounded-2xl border border-[var(--chakra-colors-whiteAlpha-100)] relative overflow-hidden shadow-md divide-y divide-[var(--chakra-colors-whiteAlpha-100)]"
    >
      <div className="py-2 px-4 hover:bg-[var(--chakra-colors-gray-750)] ">
        <div className="text-white text-sm font-medium"> {name}</div>
        <div className="flex items-center justify-start space-x-4">
          <AssetIcon asset={asset} />

          <div className="">
            <div className="text-white text-base">
              {amount} {asset.name}
            </div>
            <div className="text-[var(--chakra-colors-gray-500)] text-xs">
              {asset.chain}
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 px-4 cursor-not-allowed text-xs">
        <div className="font-bold text-white  opacity-40">Account #0</div>
        <div className="font-medium text-[var(--chakra-colors-gray-500)] opacity-40">
          {metaMask?.shortaddress}
        </div>
      </div>
    </button>
  );
};

const TradeComponent = ({ from, to, setFrom, setTo, amountPay }) => {
  return (
    <div className="relative">
      <div className="flex space-x-2 ">
        <AccountBlock
          name="From"
          asset={from}
          setAsset={setFrom}
          amount={amountPay}
        />
        <AccountBlock
          name="To"
          asset={to}
          setAsset={setTo}
          amount={amountPay}
        />
      </div>{" "}
      <MdEast
        size={32}
        onClick={() => {
          setFrom((prev) => {
            const temp = to;
            setTo(prev);
            return temp;
          });
        }} // swap
        className="-mt-5 -ml-5 absolute top-1/2 left-1/2 p-2 bg-[var(--chakra-colors-gray-800)] rounded-full text-gray-200 
          shadow-2xl border border-gray-800 z-10 hover:bg-gray-700"
      />
    </div>
  );
};

export default TradeComponent;
