import { Outlet } from "react-router-dom";
import { useLocation, Navigate } from "react-router-dom";

import useVar from "../hooks/useVar";

const VerifySignOut = () => {
  const location = useLocation();
  const { signature } = useVar();

  return signature ? (
    <Navigate to="/Dashboard" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default VerifySignOut;
