const ClipboardButton = ({ address }) => {
  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(address);
      }}
      className="active:translate-y-2 duration-500 hover:scale-105  "
    >
      {address.slice(0, 10) + "..."}
    </button>
  );
};

export default ClipboardButton;
