// import { Assets, AssetIcon } from "../js/Assets";
import icons from "../js/icons";
import QRCode from "react-qr-code";
import useVar from "../hooks/useVar";
import { shortenAddress } from "../js/utils";
// import CopyToClipboard from "../components/CopyToClipBoard";
// import { useMemo } from "react";
// import { useMemo, useState } from "react";

import { toast } from "react-hot-toast";
const IconButton = ({ name, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      className=" text-[var(--chakra-colors-gray-500)] hover:text-white 
    flex flex-col justify-center  items-center space-y-2 group cursor-pointer"
    >
      <div className="w-10 h-10 bg-gray-700 group-hover:bg-blue-600 group-hover:opacity-100 rounded-full p-3">
        {icons[name.toLowerCase()]}
      </div>
      <div className="text-base font-semibold group-hover:opacity-100">
        {name}
      </div>
    </div>
  );
};

const ModalReceive = () => {
  const { walletAddress, walletProvider } = useVar();

  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      <div className="text-center text-base font-normal text-[var(--chakra-colors-gray-500)] ">
        Only use this address with SafeCoin Wallet app. Sending any other coins
        may result in permanent loss.
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(walletAddress);
          toast.success("Address copied to clipboard", {
            duration: 5000,
          });
        }}
        className="flex items-center justify-between bg-gray-800 rounded-xl w-full py-1.5 px-3
      
      active:translate-y-2 duration-500 hover:scale-95 cursor-pointer"
      >
        <div className="text-sm font-bold text-[var(--chakra-colors-gray-500)] ">
          (Internal)Safe Wallet Address
        </div>
        <div className="text-sm font-normal text-[var(--chakra-colors-gray-600)] ">
          {shortenAddress(walletAddress)}
        </div>
      </div>

      <div
        onClick={() => {
          navigator.clipboard.writeText(walletProvider.address);
          toast.success("Address copied to clipboard", {
            duration: 5000,
          });
        }}
        className="flex items-center justify-between bg-gray-800 rounded-xl w-full py-1.5 px-3
        active:translate-y-2 duration-500 hover:scale-95 cursor-pointer"
      >
        <div className="text-sm font-bold text-[var(--chakra-colors-gray-500)] ">
          External Wallet Address
        </div>
        <div className="text-sm font-normal text-[var(--chakra-colors-gray-600)] ">
          {walletProvider.shortaddress}
        </div>
      </div>

      <div className="mt-4 p-8 bg-white rounded-xl max-w-[14rem] space-y-4">
        <QRCode
          value={walletAddress}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        />
        <div
          onClick={() => {
            navigator.clipboard.writeText(walletAddress);
            toast.success("Address copied to clipboard", {
              duration: 5000,
            });
          }}
          className=" cursor-pointer text-center text-sm font-normal
           text-[var(--chakra-colors-gray-400)] hover:text-[var(--chakra-colors-blue-400)] transition-colors "
        >
          {shortenAddress(walletAddress)}
        </div>
      </div>

      <div className="w-full py-8 flex justify-between items-center space-x-6 max-w-[14rem]">
        {/* <div className=" text-white opacity-40 flex flex-col justify-center "> */}

        {/* <RoundButton state={copyButtonState} onClick={onCopy} /> */}

        <IconButton
          name={"Copy"}
          onClick={() => {
            navigator.clipboard.writeText(walletAddress);
            toast.success("Address copied to clipboard", {
              duration: 5000,
            });
          }}
        />
        <IconButton
          name={"Verify"}
          onClick={() => {
            // toast.success("Address is OK!", {
            //   duration: 5000,
            // });
          }}
        />
        <IconButton name={"Explorer"} onClick={() => {}} />
        {/* </div> */}
      </div>
    </div>
  );
};

export default ModalReceive;
