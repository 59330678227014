import icons from "./icons";
// const sample = [
//   {
//     date: "21.1.20",
//     list: [
//       {
//         icon: <BsArrowUp className="w-full h-full" />,
//         title: "Send",
//         status: "Completed",
//         amount: "5",
//         address: "0x1234567890",
//         time: "12:00",
//         buttons: [{ onClick: () => {}, name: "View" }],
//       },
//       {
//         icon: <BsArrowDown className="w-full h-full text-green-300" />,
//         title: "Recv",
//         status: "Completed",
//         amount: "5",
//         address: "0x1234567890",
//         time: "12:00",
//         buttons: [{ onClick: () => {}, name: "View" }],
//       },
//     ],
//   },
// ];

const actionsToStatus = (action, description) => {
  if (action === "TRANSFER_TO") return description ?? "Trade";
  if (action === "TRANSFER_MINER_COIN")
    return description ?? "Exchange Transfer MC";
  if (action === "REQUEST_TRANSFER") return "Pending to Reciever approval";

  if (action === "RECIEVER_ACCEPTED") return "Pending to Sender approval";
  if (action === "RECIEVER_DECLINE") return "Canceled by Reciever";
  if (action === "RECIEVER_REFUND") return "Refunded by Reciever";
  if (action === "RECIEVER_DISPUTE") return "Disputed by Reciever";

  if (action === "DISPUTE_RESOLVED") return "Resolved Dispute";
  if (action === "SENDER_DISPUTE") return "Disputed by Sender";
  if (action === "SENDER_WITHDRAW") return "Canceled by Sender";
  if (action === "SENDER_APPROVED") return "Completed";

  return "Unknown";
};

const timeStampToDate = (timeStamp) => {
  const date = new Date(timeStamp);
  return (
    date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  );
};

const timeStampToHours = (timeStamp) => {
  const date = new Date(timeStamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
};

const convertToDate = (dateString) => {
  let dateParts = dateString.split(".");
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject.getTime();
};

const ActionsToButtons = (title, action) => {
  if (title === "Send") {
    if (action === "REQUEST_TRANSFER") {
      return [{ action: "SENDER_WITHDRAW", name: "Cancel" }];
    }
    if (action === "RECIEVER_ACCEPTED") {
      return [
        { action: "SENDER_DISPUTE", name: "Dispute" },
        { action: "SENDER_APPROVED", name: "Approve" },
      ];
    }

    if (action === "SENDER_DISPUTE") {
      return [{ action: "SENDER_APPROVED", name: "Cancel Dispute & Approve" }];
    }

    if (action === "RECIEVER_DISPUTE") {
      return [{ action: "", name: "Fill Dispute Form..." }];
    }

    return [];
  } else if (title === "Recv") {
    if (action === "REQUEST_TRANSFER") {
      return [
        { action: "RECIEVER_ACCEPTED", name: "Accept" },
        { action: "RECIEVER_DECLINE", name: "Decline" },
      ];
    }
    if (action === "RECIEVER_ACCEPTED") {
      return [
        { action: "RECIEVER_REFUND", name: "Refund" },
        { action: "RECIEVER_DISPUTE", name: "Dispute" },
      ];
    }

    if (action === "SENDER_DISPUTE") {
      return [{ action: "", name: "Fill Dispute Form..." }];
    }

    if (action === "RECIEVER_DISPUTE") {
      return [{ action: "RECIEVER_REFUND", name: "Cancel Dispute & Refund" }];
    }

    return [];
  }
  return [];
};

const listToHistory = (raw, title, history) => {
  if (!raw) return;
  Object.keys(raw).forEach(function (peerAddress) {
    Object.keys(raw[peerAddress]).forEach(function (hash) {
      const data = raw[peerAddress][hash];

      if (data.description === "hidden") return;

      history[timeStampToDate(data.timestamp)] ??= [];

      history[timeStampToDate(data.timestamp)].push({
        index: 0,
        code: "sc",
        icon: icons[title],
        hash,
        title,
        description: data.description,
        status: actionsToStatus(data.action, data.description),
        amount: data.amount / 10 ** 9,
        fee: data.fee / 10 ** 9,
        dispute: data.dispute,
        address: peerAddress,
        timestamp: data.timestamp,
        hours: timeStampToHours(data.timestamp),
        buttons: ActionsToButtons(title, data.action),
      });
    });
  });
};

const countHistory = (raw, title) => {
  if (!raw) return;

  let count = 0;
  Object.keys(raw).forEach(function (peerAddress) {
    Object.keys(raw[peerAddress]).forEach(function (hash) {
      const data = raw[peerAddress][hash];

      if (ActionsToButtons(title, data.action).length !== 0) {
        count++;
      }
    });
  });

  return count;
};
export { listToHistory, convertToDate, countHistory };
