import { useState } from "react";
import useVar from "../hooks/useVar";
import CancelButton from "./buttons/CancelButton";
import NextButton from "./buttons/NextButton";
import SendGas from "./SendGas";
// import Dropdown from "../components/Dropdown";

const ModalSendDescription = ({ tx }) => {
  const [description, setDescription] = useState("");
  const { modal } = useVar();

  return (
    <div className="space-y-6">
      {/* <Dropdown
        title={"Select a corresponding..."}
        list={["Template 1", "Template 2", "Template 3"]}
      /> */}{" "}
      <div></div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Please enter a short description of the transaction. In case of
          dispute, AI engine will analyze this information in order to resolve
          the issue.
        </div>
        <div className="h-36">
          <textarea
            type="text"
            id="description"
            autoFocus={true}
            className="h-full w-full rounded-xl text-sm opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            minLength={10}
            maxLength={1000}
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="text-xs text-gray-600 mb-5 text-right">
          ({description.length}/1000)
        </div>
      </div>
      <div className="space-y-2">
        <NextButton
          text={{ disabled: "Please insert a description", enabled: "Next" }}
          disabled={description.length < 10}
          onClick={() => {
            tx.payload.description = description;

            modal.push("Send Safe " + tx.asset.name, <SendGas tx={tx} />);
          }}
        />
        <CancelButton />
      </div>
    </div>
  );
};

export default ModalSendDescription;
