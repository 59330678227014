import { useState } from "react";
import useVar from "../hooks/useVar";
import { shortenAddress } from "../js/utils";
import RulingModal from "../modals/Ruling";

import moment from "moment";

const Ruling = () => {
  const { ruling } = useVar();

  if (ruling.length === 0) {
    return (
      <div className="min-h-full sm:p-4 bg-bg text-gray-200 ">
        <div className="p-5 text-xl sm:text-3xl ">No Ruling</div>
      </div>
    );
  }
  return (
    <div className="min-h-full sm:p-4 bg-bg text-gray-200 ">
      <div className="p-5 text-xl sm:text-3xl ">Disputes History</div>

      <div className="mx-auto bg-[var(--chakra-colors-gray-785)] border border-gray-800 sm:rounded-lg shadow-md">
        <section className={`sm:p-5 divide-y divide-gray-800`}>
          <div className="w-full text-sm text-gray-400">
            {ruling.map((item, index) => (
              <TxSection key={index} item={item} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

const ActionButton = ({ recipient, item }) => {
  const { modal } = useVar();

  return (
    <button
      onClick={() => {
        const tx = {
          amount: item.amount,
          name: "ruling",
          recipient,
          payload: {
            dispute: {
              fromAddress: item.from,
              toAddress: item.to,
              // ruling///
            },
            hash: item.hash,
            action: "DISPUTE_RESOLVED",
            description: item.description,
          },
        };
        modal.pushWithLoading("Action required", <RulingModal tx={tx} />);
      }}
      className=" bg-indigo-600 text-indigo-200 hover:bg-indigo-500 py-1 px-2 sm:px-4 rounded-md  
                                         active:translate-y-2 text-xs sm:text-base
                                        hover:shadow-md duration-500"
    >
      {shortenAddress(recipient)}
    </button>
  );
};

const TxSection = ({ item }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${
        open &&
        `bg-[var(--chakra-colors-gray-750)] rounded-lg border-opacity-20 border-gray-400 border
         divide-y divide-gray-400 divide-opacity-20`
      } 
       relative my-2 w-full font-semibold text-white hover:rounded-lg 
        hover:bg-[var(--chakra-colors-gray-750)] transition-opacity `}
    >
      <div className="flex justify-between items-center">
        {console.log(item)}
        <TxLine item={item} open={open} setOpen={setOpen} />
      </div>

      {/* <DetailedHistory show={open} item={item} /> */}
    </div>
  );
};

const TxLine = ({ item, open, setOpen }) => {
  return (
    <div className="py-4 w-full px-2 space-y-2">
      <div className="w-full flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-2 w-40 sm:w-80 ">
          {/* <div className="font-bold rounded-full bg-[var(--chakra-colors-whiteAlpha-200)] p-2 h-6 w-6 sm:h-10 sm:w-10 shadow-md">
            {item.icon}
          </div> */}

          <div className="text-xs sm:text-base space-y-2 ">
            <div className="text-gray-500 ">{item.hash}</div>
            <div className="">{item.description}</div>
            <div className="">{item.action}</div>
            <div className=" space-x-2 ">
              <ActionButton recipient={item.from} item={item} />
              <ActionButton recipient={item.to} item={item} />
            </div>
          </div>
        </div>
        <div className="mx-auto text-xs sm:text-base">
          <div className="flex items-center space-x-4">
            {/* <AssetIcon asset={Assets[3]} /> */}

            <div>
              <div className="">{(item.amount / 10 ** 9).toFixed(2)} $</div>
            </div>
          </div>
        </div>{" "}
        {/* <button
          onClick={() => {
            copyToClipboard(item.address);
          }}
          className="text-indigo-400  active:translate-y-2 duration-500 hidden sm:block"
        >
          {shortenAddress(item.address)}
        </button> */}
        <div className="text-base">{moment(item.timestamp).fromNow()}</div>
      </div>
    </div>
  );
};
export default Ruling;
