const CopyToClipboard = ({ children, copy }) => {
  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(copy);
      }}
      className="active:translate-y-2 duration-500 hover:scale-105  "
    >
      {children}
    </button>
  );
};

export default CopyToClipboard;
