import { useCallback } from "react";
import useVar from "../hooks/useVar";

const useProviderMetaMask = () => {
  const { walletProvider } = useVar();
  const { setProviderType } = walletProvider;

  const connect = useCallback(async () => {
    setProviderType("MetaMask");
  }, [setProviderType]);

  return { connect };
};

export default useProviderMetaMask;
