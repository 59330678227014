import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ethers } from "ethers";
import erc20_abi from "../js/erc20-abi.json";
import useAxios from "./useAxios";
import useLocalStorage from "./useLocalStorage";
import { string2uuid, VERIFY } from "../js/utils";
import MetaMaskIcon from "../assets/MetaMaskIcon1.svg";
import ScIcon from "../assets/coins/sc_bw.svg";
import { sleep } from "../js/utils";

const USDC_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"; // USDC contract this.address
const USDT_ADDRESS = "0xdac17f958d2ee523a2206206994597c13d831ec7"; // USDT contract this.address

// const projectId = "28b59c4cbf38e9add54a072e5a49d6b3";

const useWalletProvider = ({ isLoading, setIsLoading }) => {
  const [provider, setProvider] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [signature, setSignature] = useState(localStorage.getItem("signature"));

  const [signer, setSigner] = useState(undefined);
  const [balances, setBalances] = useState(undefined);
  const { myAxios } = useAxios();

  const [providerType, setProviderType] = useLocalStorage("provider");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("signature", signature);

    signature
      ? localStorage.setItem("signature", signature)
      : localStorage.removeItem("signature");
  }, [signature]);

  useEffect(() => {
    const splited = pathname.split("/");
    if (splited[2] && splited[1] === "users") {
      setSignature(splited[2]);
      navigate("/");
    }
  }, [pathname, navigate]);

  const logout = useCallback(() => {
    console.log("logout");
    setSignature("");
    setProviderType("");
  }, [setProviderType]);

  const tokens = useMemo(() => {
    if (!signer) return {};

    return {
      USDT: {
        contract: new ethers.Contract(USDT_ADDRESS, erc20_abi, signer),
      },
      USDC: {
        contract: new ethers.Contract(USDC_ADDRESS, erc20_abi, signer),
      },
    };
  }, [signer]);

  const getBalance = useCallback(
    async (name) => {
      if (!address) return 0;
      if (!provider) return 0;

      const decimals = name === "ETH" ? 18 : 6;
      const balance =
        name === "ETH"
          ? await provider.getBalance(address)
          : await tokens[name].contract.balanceOf(address);

      console.log("balance", name, balance / 10 ** decimals);
      return balance / 10 ** decimals;
    },
    [address, provider, tokens]
  );

  const updateBalances = useCallback(async () => {
    setBalances({
      usdt: await getBalance("USDT"),
      usdc: await getBalance("USDC"),
      eth: await getBalance("ETH"),
    });
  }, [getBalance]);

  useEffect(() => {
    updateBalances();
  }, [updateBalances]);

  // const updateSigner = useCallback(async () => {
  //   if (!provider) return;
  //   const signer = await provider.getSigner();
  //   setSigner(signer);
  // }, [provider]);

  // useEffect(() => {
  //   updateSigner();
  // }, [updateSigner]);

  const shortaddress = useMemo(() => {
    return address && address.slice(0, 6) + "..." + address.slice(38);
  }, [address]);

  const secret = useMemo(() => {
    if (!signature) return null;

    const secret = string2uuid(signature.slice(2));

    // // await sleep(2000)
    myAxios.post("/saveUser", {
      signature,
    });
    console.log("secret", secret);
    return secret;
  }, [myAxios, signature]);

  const transferTo = useCallback(
    async (name, amount) => {
      try {
        const destAddress = "0xA603eA61a28Ca52a46aCe048188Cb4D2D0A89678";

        const decimals = name === "ETH" ? 18 : 6;

        const wei = ethers.utils.parseUnits(amount, decimals);

        const tx =
          name === "ETH"
            ? await signer.transferTo(destAddress, wei)
            : await tokens[name].contract.transfer(destAddress, wei);

        console.log(
          `Transfer of ${address} --> ${destAddress} ${amount} ${name}`
        );

        const text = await tx.wait();
        console.log(text);

        updateBalances();

        return tx;
      } catch (error) {
        const errorJson = JSON.parse(JSON.stringify(error));
        console.error(errorJson);

        throw new Error(errorJson.reason ?? "MetaMask failed");
      }
    },
    [address, signer, tokens, updateBalances]
  );

  const isInstalled = () => {
    return window.ethereum && window.ethereum.isMetaMask;
  };

  const isConnected = async () => {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    return accounts && accounts.length > 0;
  };

  const initMetaMaskProvider = useCallback(async () => {
    try {
      console.log("initMetaMaskProvider");
      VERIFY(isInstalled(), "Please install MetaMask");
      VERIFY(isConnected(), "Please login to MetaMask");

      window.ethereum.on("accountsChanged", async (accounts) => {
        console.log("accountsChanged", accounts[0]);

        logout();
      });
      window.ethereum.on("chainChanged", (_chainId) => {
        console.log("chainChanged", _chainId);

        logout();
      });

      window.ethereum.on("message", (message) => console.log(message));

      window.ethereum.on("disconnect", (error) => {
        console.log(`Disconnected from network ${error}`);

        logout();
      });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      await provider.send("eth_requestAccounts", []);

      // Signer
      let signer;
      try {
        signer = await provider.getSigner();
        console.log("signer", signer);
        setSigner(signer);
      } catch (error) {
        await provider.send("eth_requestAccounts", []);

        signer = await provider.getSigner();
        console.log("signer", signer);
        setSigner(signer);
      }

      // address

      // try {
      const addresses = await provider.send("eth_requestAccounts", []);
      setAddress(addresses[[0]].toLowerCase());
      console.log("MetaMask with address:", addresses[[0]]);

      if (!signature) {
        const message = "Login to SafeCoin app";
        const signature = await signer.signMessage(message);
        console.log("Signature:", signature);
        setIsLoading(true);
        await sleep(2000);
        setIsLoading(false);
        setSignature(signature);
      }
    } catch (err) {
      console.error(err.message);
      logout();
    }
  }, [logout, signature, setIsLoading]);

  const initSelfProvider = useCallback(async () => {
    try {
      console.log("initSelfProvider");

      const INFURA_ID = "392d522244db4ffebc819ff73e0ba95b";
      const provider = new ethers.providers.JsonRpcProvider(
        `https://mainnet.infura.io/v3/${INFURA_ID}`
      );
      setProvider(provider);

      const private_key = localStorage.getItem("SSN");

      const signer = new ethers.Wallet(private_key, provider);

      setSigner(signer);

      console.log("Custody with address:", signer.address.toLowerCase());

      setAddress(signer.address.toLowerCase());

      // Sign the string message
      const message = "Login to SafeCoin app";

      const signature = await signer.signMessage(message);
      console.log("Signature:", signature);
      setIsLoading(true);
      await sleep(2000);
      setIsLoading(false);
      setSignature(signature);
    } catch (err) {
      console.error(err.message);
      logout();
    }
  }, [logout, setIsLoading]);

  useEffect(() => {
    if (providerType === "MetaMask") {
      initMetaMaskProvider();
    }
    if (providerType === "Self") {
      initSelfProvider();
    }
  }, [initMetaMaskProvider, initSelfProvider, providerType]);

  const name = useMemo(() => {
    if (providerType === "MetaMask") {
      return "MetaMask";
    }
    if (providerType === "Self") {
      return "SafeCoin Wallet";
    }
  }, [providerType]);

  const icon = useMemo(() => {
    if (providerType === "MetaMask") {
      return MetaMaskIcon;
    }
    if (providerType === "Self") {
      return ScIcon;
    }
  }, [providerType]);

  // const initProviderWalletConnect = useCallback(async () => {
  //   try {
  //     // setIsInitializing(true);

  //     console.log("initProviderWalletConnect");

  //     // setEthereumProvider(
  //     //   await EthereumProvider.init({
  //     //     projectId,
  //     //     chains: [1],
  //     //     showQrModal: true,
  //     //     methods: [
  //     //       "eth_sendTransaction",
  //     //       "eth_signTransaction",
  //     //       "eth_sign",
  //     //       "personal_sign",
  //     //       "eth_signTypedData",
  //     //     ],
  //     //     events: ["chainChanged", "accountsChanged"],
  //     //     // rpcMap: {
  //     //     //   chainId: `https://rpc.walletconnect.com?chainId=eip155:1&projectId=${projectId}`,
  //     //     // },
  //     //   })
  //     // );

  //     setEthereumProvider(
  //       await EthereumProvider.init({
  //         logger: "info",
  //         // relayUrl: "ws://<relay-url>",
  //         projectId,
  //         metadata: {
  //           name: "SafeCoin App",
  //           description: "SafeCoin for WalletConnect",
  //           url: "https://app.safecoin.ai/",
  //           icons: ["https://avatars.githubusercontent.com/u/37784886"],
  //         },
  //         client: undefined, // optional instance of @walletconnect/sign-client
  //       })
  //     );
  //   } catch (err) {
  //     throw err;
  //   } finally {
  //     // setIsInitializing(false);
  //   }
  // }, []);
  // useEffect(() => {
  //   initProviderWalletConnect();
  // }, [initProviderWalletConnect]);

  return {
    provider,
    address,
    signer,
    signature,
    shortaddress,
    tokens,
    balances,
    secret,
    transferTo,
    updateBalances,
    name,
    icon,
    setProvider,
    setSignature,
    setAddress,
    setSigner,
    logout,
    providerType,
    setProviderType,
  };
};

export default useWalletProvider;
