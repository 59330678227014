import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";

import useVar from "../hooks/useVar";
import useMobileDetect from "../hooks/useMobileDetect";
import icons from "../js/icons";
// import { isBrowser } from "react-device-detect";
// import { BrowserView } from "react-device-detect";

export default function ModalComp() {
  const { modal, receivedMessage } = useVar();

  const cancelButtonRef = useRef(null);

  const { isMobile } = useMobileDetect();

  return (
    <Transition.Root show={modal.list.at(-1)?.show} as={Fragment}>
      {/* {isMobile() && <BrowserView></BrowserView>} */}
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        // onClose={() => {}}
        onClose={
          receivedMessage?.type !== "pending" && !isMobile
            ? modal.close
            : () => {}
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="bg-[var(--chakra-colors-gray-785)] relative transform overflow-hidden rounded-2xl px-6 py-4 
                                       text-left shadow-black shadow-xl transition-all w-full max-w-lg"
              >
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-6 text-gray-100  "
                >
                  <div className="flex items-center justify-between space-x-4">
                    <button
                      onClick={modal.pop}
                      className={`${
                        modal.list.length > 2
                          ? "hover:bg-[var(--chakra-colors-gray-700)] "
                          : "opacity-0 cursor-default"
                      }  p-1 rounded-full w-6 h-6 transition`}
                    >
                      {icons.arrowLeft}
                    </button>
                    <div className="">{modal.list.at(-1)?.title}</div>
                    <button
                      onClick={
                        receivedMessage?.type !== "pending"
                          ? modal.close
                          : () => {}
                      }
                    >
                      <RxCross2 className="hover:bg-[var(--chakra-colors-gray-700)] p-1 rounded-full w-7 h-7 transition" />
                    </button>
                  </div>
                </Dialog.Title>
                <div className="relative overflow-x-auto space-y-4 mt-6">
                  {modal.list.at(-1)?.body}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
