import { useState } from "react";
import useVar from "../hooks/useVar";
import SendAmount from "./SendAmount";
import AttentionUnsafeSendingModal from "./AttentionUnsafeSendingModal";
import CancelButton from "./buttons/CancelButton";
import NextButton from "./buttons/NextButton";

import QrReader from "../components/QrReader";

const ModalSendAddress = ({ asset }) => {
  const [recipient, setRecipient] = useState("");
  const { modal, signature } = useVar();

  return (
    <div className="space-y-10">
      <div className=""></div>
      <div className="space-y-2">
        <div className="text-base font-semibold text-[var(--chakra-colors-gray-500)] ">
          Send To
        </div>
        <div className="relative">
          <input
            type="text"
            autoFocus={true}
            className="h-12 w-full pr-10 rounded-xl opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            id="recipient"
            maxLength="62"
            minLength="66"
            placeholder="To Address"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            required
          />

          <QrReader setCode={setRecipient} />
        </div>
      </div>

      <div className="space-y-2">
        <NextButton
          text={{
            error: "Invalid SafeCoin Address",
            disabled: "Please insert remote address",
            enabled: "Next",
          }}
          disabled={recipient.length === 0}
          error={
            recipient.length > 0 &&
            recipient.length !== 66 &&
            recipient.length !== 42
          }
          onClick={() => {
            const tx = { asset, recipient, signature };

            if (recipient.length === 42) {
              modal.push(
                "Sending un-safe " + asset.name,
                <AttentionUnsafeSendingModal tx={tx} />
              );
              return;
            }
            modal.push("Send Safe " + asset.name, <SendAmount tx={tx} />);
          }}
        />
        <CancelButton />
      </div>
    </div>
  );
};

export default ModalSendAddress;
