import useVar from "../../hooks/useVar";

const CancelButton = () => {
  const { modal } = useVar();

  return (
    <button
      onClick={modal.close}
      className="w-full rounded-lg text-lg font-bold hover:bg-[var(--chakra-colors-gray-800)] py-2 "
    >
      Cancel
    </button>
  );
};

export default CancelButton;
