import { useEffect } from "react";

const PayBy = () => {
  useEffect(() => {}, []);

  return (
    <div className="bg-black h-screen text-gray-300 ">
      <div className="flex max-md:flex-col">
        <div className="h-full  px-10 py-8 max-w-3xl border-gray-700 border bg-gray-900 space-y-4">
          <div className="bg-gray-800 text-xl font-bold p-10">
            Order details
          </div>
          <div className="flex space-x-4 text-2xl">
            <div className="">icon</div>
            <div className="">
              <div className="">h1</div>
              <div className="">h2</div>
            </div>
          </div>
          <div className="divder-y divide-gray-700"></div>
          <div className="text-2xl">Summery</div>
          <div className="text-2xl">1000</div>
        </div>
      </div>
    </div>
  );
};

export default PayBy;
