import useVar from "../hooks/useVar";
import ScWalletImportMemonic from "./ScWalletImportMemonic";
import ScWalletImportPrivateKey from "./ScWalletImportPrivateKey";

const Button = ({ onClick, text }) => {
  return (
    <button
      onClick={() => {
        onClick();
      }}
      className={`pl-4 flex justify-between text-base text-gray-100 w-full rounded-lg font-semibold  bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-700)] py-2 `}
    >
      <div className="">{text}</div>
      {/* <div className="pr-4">-></div> */}
    </button>
  );
};

const ScWalletMenu = () => {
  const { modal } = useVar();

  return (
    <div className="md:p-4 space-y-10 max-md">
      <div className="space-y-2 space-x-4 "></div>

      <div className="space-y-2">
        <Button
          text={"A Secret Recovery Phrase"}
          onClick={() => {
            modal.push(
              "Your Secret Recovery Phrase",
              <ScWalletImportMemonic />
            );
          }}
        />

        <Button
          text={"Private Key"}
          onClick={() => {
            modal.push("Import a Wallet", <ScWalletImportPrivateKey />);
          }}
        />
      </div>
    </div>
  );
};

export default ScWalletMenu;
