import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { MdEast } from "react-icons/md";
import useVar from "../hooks/useVar";
import { buy_coins } from "../js/coins";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BuyTab = () => {
  const { walletAddress, secret, restApi, setRampModal } = useVar();

  const [selected, setSelected] = useState(buy_coins[0]);
  const [amount, setAmount] = useState("");
  const [originWallet, setOriginWallet] = useState("");

  const onBuy = async (e) => {
    e.preventDefault();

    if (selected.ticker === "Credit Card") {
      setRampModal(true);
      return;
    }

    restApi.buy({
      walletAddress,
      symbol: selected.ticker,
      amount: amount * 10 ** 9,
      secret,
    });
  };

  return (
    <div className="p-5 rounded-b-lg bg-gray-800 shadow-lg max-w-sm">
      <form onSubmit={onBuy}>
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <>
              <Listbox.Label className="font-bold mt-5">
                Buy with:
              </Listbox.Label>
              <div className="text-gray-400 text-base my-1">
                Choose from a growing list of cryptocurrencies.
              </div>
              <div className="relative mt-3">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm
                 focus:border-gray-500 focus:outline-none sm:text-sm hover:bg-bg"
                >
                  <span className="flex items-center">
                    {selected.image ? (
                      <img
                        src={selected.image}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      />
                    ) : (
                      selected.icon
                    )}

                    <span className="ml-3 block truncate">{selected.name}</span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-bg py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {buy_coins.map((item, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active ? "text-white bg-indigo-600" : "text-white",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              {item.image ? (
                                <img
                                  src={item.image}
                                  alt=""
                                  className="h-6 w-6 flex-shrink-0 rounded-full"
                                />
                              ) : (
                                item.icon
                              )}
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {item.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <div className="mt-5 font-bold">{`Amount of SC to Buy: `}</div>
        <input
          placeholder={`10 SC minimum`}
          className="w-full rounded-lg border-gray-500 my-3 focus:border-gray-400 focus:ring-0 bg-gray-800 mb-5"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          step="0.01"
          id="amount"
          min={10}
          maxLength="10"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        {selected.ticker !== "Credit Card" && (
          <>
            <div className=" font-bold">Origin address of sending funds:</div>
            <div className="text-gray-500 text-sm my-1">
              ERC-20 address only suppported.
            </div>
            <input
              type="text"
              placeholder="Enter your wallet address"
              className="w-full rounded-lg border-gray-500 my-3 focus:border-gray-400 focus:ring-0 bg-gray-800 mb-5"
              id="withdraw"
              maxLength="66"
              value={originWallet}
              onChange={(e) => setOriginWallet(e.target.value)}
              required
            />
          </>
        )}
        <button
          type="submit"
          className=" group mt-12 w-full rounded-lg bg-slate-700 py-2 hover:bg-slate-600
         active:translate-y-1 duration-500 shadow-lg flex justify-center uppercase  mb-10"
        >
          {/* spinner={true} */}
          Buy Now
          <MdEast
            size={20}
            className="ml-2 my-auto group-hover:translate-x-[0.5rem]  duration-500"
          />
        </button>
      </form>
    </div>
  );
};

export default BuyTab;
