import { toast, Toaster, ToastBar } from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
// import ScImg from "../assets/coins/sc.svg";
// import ScImg from "../assets/logo.png";
import ScImg from "../assets/coins/sc_bw.svg";

function App() {
  return (
    <Toaster
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
          fontSize: "1rem",
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>
                  <MdOutlineClose
                    size={20}
                    className="ml-2 my-auto hover:scale-125 duration-500 hover:bg-zinc-800 hover:p-1 rounded-lg"
                  />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export const YesNo = (callback, description) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "translate-y-0" : "-translate-y-96 "
      } max-w-md w-full bg-[#333333] shadow-lg rounded-lg pointer-events-auto flex transition-all duration-500 `}
    >
      <div className="rounded-lg text-left shadow-xl transition-all  sm:w-full sm:max-w-xl">
        <div className="p-1 sm:p-6 ">
          <div className="flex items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  sm:mx-0 sm:h-10 sm:w-10">
              <img src={ScImg} alt="sc" className="h-10 mr-2 " />
            </div>
            <div className="ml-4 text-left text-sm sm:text-base font-medium leading-6">
              <div className=" text-gray-100">
                Are you sure you want to execute this operation?
              </div>

              {description && (
                <div className=" bg-white bg-opacity-5 mt-2 p-2 rounded-lg border border-white border-opacity-10">
                  <div className=" text-gray-300 underline text-sm">Transaction Description:</div>
                  <div className=" text-gray-400 text-sm">{description}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 py-2 flex flex-row-reverse sm:px-6 ">
          <button
            type="button"
            onClick={() => {
              callback();
              toast.dismiss(t.id);
            }}
            className="ml-2 rounded-md bg-indigo-100 px-4 py-1 text-sm font-medium text-indigo-900 hover:bg-indigo-300 active:translate-y-2 duration-500 ease-in-out "
          >
            Yes
          </button>

          <button
            type="button"
            onClick={() => toast.dismiss(t.id)}
            className="rounded-md bg-indigo-100 px-4 py-1 text-sm font-medium text-indigo-900 hover:bg-indigo-300 active:translate-y-2 duration-500 ease-in-out "
          >
            No
          </button>
        </div>
      </div>
    </div>
  ));
};

export default App;
