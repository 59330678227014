import useVar from "../../hooks/useVar";

const CancelButton = ({ tx }) => {
  const { modal } = useVar();

  return (
    <button
      onClick={() => {
        tx.step--;

        modal.pop();
      }}
      className="w-full rounded-lg text-lg font-bold hover:bg-[var(--chakra-colors-gray-800)] py-2 "
    >
      Back
    </button>
  );
};

export default CancelButton;
