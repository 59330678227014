import { useState } from "react";

import useVar from "../hooks/useVar";
import MultiButton from "./buttons/MultiButton";
import DisputeTextModal from "./DisputeTextModal";
//import useCreateTransaction from "../hooks/useCreateTransaction";

const DisputeEvidencesModal = ({ tx }) => {
  const [description, setDescription] = useState("");
  const newLocal = useVar();
  const { modal, receivedMessage } = newLocal;

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (selectedFiles.length === 0) {
      await modal.addState("error", "Please select at least one file.");
      await modal.addState("action", "Confirm");
      return;
    }

    if (selectedFiles.length > 4) {
      await modal.addState("error", "Please select no more than 4 files.");
      await modal.addState("action", "Confirm");
      return;
    }

    const maxFileSizeInBytes = 10 * 1024 * 1024; // 10MB

    const formData = new FormData();

    for (const file of selectedFiles) {
      if (file.size > maxFileSizeInBytes) {
        await modal.addState(
          "error",
          `File ${file.name} is too large. Please select a file smaller than 10MB.`
        );
        await modal.addState("action", "Confirm");

        return;
      }

      formData.append(`files`, file);
    }

    // await modal.addState("pending", "Connecting to Blockchain...");
    // await modal.addState("pending", "Connected.");

    // formData.append("evidencesDescription", description);

    tx.evidencesDescription = description;

    modal.push(
      "Dispute Form (1/11)",
      <DisputeTextModal tx={tx} step={0} formData={formData} />
    );
  };

  return (
    <div className="space-y-6">
      <div></div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Please upload the evidence you have and explain the content of the
          evidence in the description.
        </div>
        <div className="h-36">
          <textarea
            type="text"
            id="description"
            autoFocus={true}
            className="h-full w-full rounded-xl text-sm opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            minLength={10}
            maxLength={1000}
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="text-xs text-gray-600 mb-5 text-right">
          ({description.length}/1000)
        </div>
      </div>

      <input className="" type="file" multiple onChange={handleFileChange} />

      <form onSubmit={onSubmit} className="space-y-4">
        <div className="space-y-2">
          <MultiButton state={receivedMessage} />
        </div>
      </form>
    </div>
  );
};

export default DisputeEvidencesModal;
