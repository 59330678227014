import { GiMining, GiPayMoney } from "react-icons/gi";
import {
  AiOutlineUser,
  AiOutlineInbox,
  AiOutlineNodeCollapse,
} from "react-icons/ai";
import { GoDeviceMobile } from "react-icons/go";
const copy = (
  <svg viewBox="0 0 24 24" focusable="false">
    <path
      fill="currentColor"
      d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
    ></path>
  </svg>
);
const gas = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
  >
    <path d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"></path>
  </svg>
);

const explorer = (
  <svg viewBox="0 0 24 24" focusable="false">
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
      <path d="M15 3h6v6"></path>
      <path d="M10 14L21 3"></path>
    </g>
  </svg>
);

const triangle = (
  <svg viewBox="0 0 24 24" focusable="false">
    <path
      fill="currentColor"
      d="M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z"
    ></path>
  </svg>
);
const trade = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    focusable="false"
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M464 208L352 96 240 208m112-94.87V416M48 304l112 112 112-112m-112 94V96"
    ></path>
  </svg>
);

const cards = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 576 512"
    focusable="false"
  >
    <path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"></path>
  </svg>
);

const arrowDown = (
  <svg viewBox="0 0 24 24" focusable="false">
    <path
      fill="currentColor"
      d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
    ></path>
  </svg>
);

const Recv = (
  <svg viewBox="0 0 24 24" focusable="false" className="text-green-400">
    <path
      fill="currentColor"
      d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
    ></path>
  </svg>
);

const arrowUp = (
  <svg viewBox="0 0 24 24" focusable="false">
    <path
      fill="currentColor"
      d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
    ></path>
  </svg>
);

const arrowLeft = (
  <svg viewBox="0 0 24 24" focusable="false" className="rotate-90">
    <path
      fill="currentColor"
      d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
    ></path>
  </svg>
);

const dashboard = (
  <svg viewBox="0 0 24 24" className="" focusable="false">
    <g fill="currentColor" fillRule="nonzero">
      <g id="circular-graph">
        <path
          d="M20.23,0H3.77C1.69,0,0,1.69,0,3.77V20.23c0,2.08,1.69,3.77,3.77,3.77H20.23c2.08,0,3.77-1.69,3.77-3.77V3.77c0-2.08-1.69-3.77-3.77-3.77ZM3.77,2.06H20.23c.95,0,1.71,.77,1.71,1.71v.76L13.45,13.23l-3.23-4.53c-.19-.26-.48-.44-.8-.47s-.65,.07-.88,.29L2.06,14.46V3.77c0-.95,.77-1.71,1.71-1.71ZM20.23,21.94H3.77c-.95,0-1.71-.77-1.71-1.71v-2.71s.04-.02,.05-.03l7.01-6.43,3.27,4.59c.19,.27,.5,.45,.84,.47,.33,.03,.66-.1,.9-.34l7.82-8.01v12.45c0,.95-.77,1.71-1.71,1.71Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

const transactions = (
  <svg className="" viewBox="0 0 24 24" focusable="false">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77143 2.05714C2.82465 2.05714 2.05714 2.82465 2.05714 3.77143V20.2286C2.05714 21.1753 2.82465 21.9429 3.77143 21.9429H20.2286C21.1753 21.9429 21.9429 21.1753 21.9429 20.2286V3.77143C21.9429 2.82465 21.1753 2.05714 20.2286 2.05714H3.77143ZM0 3.77143C0 1.68853 1.68853 0 3.77143 0H20.2286C22.3115 0 24 1.68853 24 3.77143V20.2286C24 22.3115 22.3115 24 20.2286 24H3.77143C1.68853 24 0 22.3115 0 20.2286V3.77143Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48571 6.51429C5.48571 5.94622 5.94622 5.48571 6.51429 5.48571H6.528C7.09606 5.48571 7.55657 5.94622 7.55657 6.51429C7.55657 7.08235 7.09606 7.54286 6.528 7.54286H6.51429C5.94622 7.54286 5.48571 7.08235 5.48571 6.51429ZM10.9714 6.51429C10.9714 5.94622 11.4319 5.48571 12 5.48571H17.4857C18.0538 5.48571 18.5143 5.94622 18.5143 6.51429C18.5143 7.08235 18.0538 7.54286 17.4857 7.54286H12C11.4319 7.54286 10.9714 7.08235 10.9714 6.51429ZM5.48571 12C5.48571 11.4319 5.94622 10.9714 6.51429 10.9714H6.528C7.09606 10.9714 7.55657 11.4319 7.55657 12C7.55657 12.5681 7.09606 13.0286 6.528 13.0286H6.51429C5.94622 13.0286 5.48571 12.5681 5.48571 12ZM10.9714 12C10.9714 11.4319 11.4319 10.9714 12 10.9714H17.4857C18.0538 10.9714 18.5143 11.4319 18.5143 12C18.5143 12.5681 18.0538 13.0286 17.4857 13.0286H12C11.4319 13.0286 10.9714 12.5681 10.9714 12ZM5.48571 17.4857C5.48571 16.9177 5.94622 16.4571 6.51429 16.4571H6.528C7.09606 16.4571 7.55657 16.9177 7.55657 17.4857C7.55657 18.0538 7.09606 18.5143 6.528 18.5143H6.51429C5.94622 18.5143 5.48571 18.0538 5.48571 17.4857ZM10.9714 17.4857C10.9714 16.9177 11.4319 16.4571 12 16.4571H17.4857C18.0538 16.4571 18.5143 16.9177 18.5143 17.4857C18.5143 18.0538 18.0538 18.5143 17.4857 18.5143H12C11.4319 18.5143 10.9714 18.0538 10.9714 17.4857Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

const db = (
  <svg viewBox="0 0 24 24" focusable="false">
    <g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21983 3.19027C4.53621 4.03193 3.84615 5.02412 3.84615 5.84615C3.84615 6.66818 4.53621 7.66037 6.21983 8.50204C7.84832 9.31614 10.1626 9.84615 12.7692 9.84615C15.3758 9.84615 17.6901 9.31614 19.3186 8.50204C21.0022 7.66037 21.6923 6.66818 21.6923 5.84615C21.6923 5.02412 21.0022 4.03193 19.3186 3.19027C17.6901 2.37616 15.3758 1.84615 12.7692 1.84615C10.1626 1.84615 7.84832 2.37616 6.21983 3.19027ZM23.5385 5.84615C23.5385 3.94818 22.0242 2.47884 20.1441 1.53896C18.2089 0.571528 15.6002 0 12.7692 0C9.93829 0 7.32952 0.571528 5.39432 1.53896C3.51425 2.47884 2 3.94818 2 5.84615V18.1538C2 20.0518 3.51425 21.5212 5.39432 22.461C7.32952 23.4285 9.93829 24 12.7692 24C15.6002 24 18.2089 23.4285 20.1441 22.461C22.0242 21.5212 23.5385 20.0518 23.5385 18.1538V5.84615ZM21.6923 9.17851C21.2286 9.5484 20.7025 9.87422 20.1441 10.1533C18.2089 11.1208 15.6002 11.6923 12.7692 11.6923C9.93829 11.6923 7.32952 11.1208 5.39432 10.1533C4.83597 9.87422 4.30989 9.5484 3.84615 9.17851V12C3.84615 12.822 4.53621 13.8142 6.21983 14.6559C7.84832 15.47 10.1626 16 12.7692 16C15.3758 16 17.6901 15.47 19.3186 14.6559C21.0022 13.8142 21.6923 12.822 21.6923 12V9.17851ZM21.6923 15.3324C21.2286 15.7022 20.7025 16.0281 20.1441 16.3072C18.2089 17.2746 15.6002 17.8462 12.7692 17.8462C9.93829 17.8462 7.32952 17.2746 5.39432 16.3072C4.83597 16.0281 4.30989 15.7022 3.84615 15.3324V18.1538C3.84615 18.9759 4.53621 19.9681 6.21983 20.8097C7.84832 21.6238 10.1626 22.1538 12.7692 22.1538C15.3758 22.1538 17.6901 21.6238 19.3186 20.8097C21.0022 19.9681 21.6923 18.9759 21.6923 18.1538V15.3324Z"
      ></path>
    </g>
  </svg>
);

const verify = (
  <svg viewBox="0 0 24 24" focusable="false">
    <g fill="currentColor">
      <path d="M23.432,10.524C20.787,7.614,16.4,4.538,12,4.6,7.6,4.537,3.213,7.615.568,10.524a2.211,2.211,0,0,0,0,2.948C3.182,16.351,7.507,19.4,11.839,19.4h.308c4.347,0,8.671-3.049,11.288-5.929A2.21,2.21,0,0,0,23.432,10.524ZM7.4,12A4.6,4.6,0,1,1,12,16.6,4.6,4.6,0,0,1,7.4,12Z"></path>
      <circle cx="12" cy="12" r="2"></circle>
    </g>
  </svg>
);

const qr = (
  <svg viewBox="0 0 448 512" focusable="false">
    <path
      fill="currentColor"
      d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"
    ></path>
  </svg>
);
const cross = (
  <svg stroke="currentColor" viewBox="0 0 15 15">
    <path
      fill="currentColor"
      d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
    ></path>
  </svg>
);

const barak = (
  <svg viewBox="0 0 384 512" focusable="false">
    <g>
      <path
        fill="currentColor"
        d="M317.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224C.9 240.7-2.6 254.8 2 267.3S18.7 288 32 288H143.5L66.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H240.5L317.4 44.6z"
      ></path>
    </g>
  </svg>
);

const icons = {
  qr,
  barak,
  cross,
  arrowUp,
  arrowDown,
  arrowLeft,
  trade,
  cards,
  explorer,
  dashboard,
  transactions,
  triangle,
  assets: db,
  gas,
  copy,
  verify,
  Send: arrowUp,
  Recv,
  Trade: trade,
  "buy/sell": cards,
  my: dashboard,
  marchant: <GiPayMoney className="text-2xl" />,
  mobile: <GoDeviceMobile className="text-2xl" />,
  mining: <GiMining className="text-2xl" />,
  funds: <GiMining className="text-2xl" />,
  inbox: <AiOutlineInbox className="text-2xl" />,
  ruling: <AiOutlineNodeCollapse className="text-2xl" />,
  users: <AiOutlineUser className="text-2xl" />,
};
export default icons;
