import Trade from "../components/Trade";

const TradePage = () => {
  return (
    <div className="min-h-full bg-bg border-[var(--chakra-colors-gray-750)]">
      <div className="py-6 px-8  border-[var(--chakra-colors-gray-750)] border space-y-4">
        <div className="font-bold text-3xl ">Trade</div>
      </div>

      <div className="sm:p-8 sm:flex justify-center">
        <div className="w-full sm:max-w-xl">
          <Trade />
        </div>
      </div>
    </div>
  );
};

export default TradePage;
