import NextButton from "./buttons/NextButton";
import lock from "../assets/lock.svg";

import useSelfCustody from "../hooks/useSelfCustody";

const ScWalletCreateDone = ({ wallet }) => {
  const { connect } = useSelfCustody(wallet);

  return (
    <div className="md:p-4 space-y-10">
      <div className="space-y-2 space-x-4">
        <img className="mx-auto p-4" src={lock} alt="" />

        <div className="text-lg text-gray-100">
          You hold the keys and you're the only one who can access it.
        </div>

        <div className="text-base text-gray-400">
          A self-custody wallet means that you own it, and only you have access
          to your funds. Your secret recovery phrase is your key to accessing
          it. Nobody can block you from accessing your funds.
        </div>
      </div>
      <div className="space-y-2 "></div>

      <NextButton
        text={{
          enabled: "Go to wallet",
        }}
        onClick={connect}
      />
    </div>
  );
};

export default ScWalletCreateDone;
