import Recent from "../components/Recent";
import WalletAssets from "../components/WalletAssets";
import { Assets, AssetIcon } from "../js/Assets";
import useVar from "../hooks/useVar";
import icons from "../js/icons";

import ReceiveModal from "../modals/SafeReceive";
import SendAddress from "../modals/SendAddress";

import { useParams, useNavigate } from "react-router-dom";

const Button = ({ name, icon, onClick, disabled }) => {
  return (
    <button
      onClick={() => {
        if (!disabled) onClick();
      }}
      className={`${
        disabled && "cursor-not-allowed opacity-40 "
      } bg-[var(--chakra-colors-gray-700)] hover:bg-[var(--chakra-colors-gray-600)] rounded-xl transition py-2 px-4`}
    >
      <div className="flex items-center text-white space-x-2 font-semibold">
        <div className="w-4 h-4">{icons[icon]}</div>
        <div className="">{name}</div>
      </div>
    </button>
  );
};

const AssetPage = () => {
  const { id } = useParams();

  const asset = Assets[id];

  const { modal, runLoading, metaMask, balances } = useVar();
  const navigate = useNavigate();

  const goto = (to) => {
    runLoading(() => {
      navigate(to);
    });
  };

  return (
    <div className="min-h-full bg-bg border-[var(--chakra-colors-gray-750)]">
      <div className="py-4 px-8  border-[var(--chakra-colors-gray-750)] border space-y-4">
        <div className="flex items-center space-x-2">
          <div className="text-[var(--chakra-colors-gray-500)] text-sm font-medium">
            Asset / {asset.name}
          </div>

          <div
            className="text-[10px] text-[var(--chakra-colors-blue-200)] hover:underline 
            py-1 px-2 bg-[var(--bg-address-color)] rounded-md font-bold cursor-pointer"
          >
            {metaMask?.shortaddress}
          </div>
        </div>
        <div className="sm:flex sm:justify-between space-y-4">
          {" "}
          <div className="flex items-center space-x-8">
            <AssetIcon asset={asset} />
            <div className="font-bold text-2xl ">{asset.long}</div>
            {/* <button
              className="p-3 text-base text-[var(--chakra-colors-blue-200)]
                       hover:bg-[var(--bg-address-color)] rounded-md transition"
            >
              <div className="w-4 h-4">{icons.explorer}</div>
            </button>{" "} */}
          </div>
          <div className="flex items-center space-x-2 sm:space-x-4">
            <span className="hidden">
              <Button
                name={"Trade"}
                icon={"trade"}
                onClick={() => {
                  goto("/Trade");
                }}
              />
              <Button
                name={"Buy/Sell"}
                icon={"cards"}
                onClick={() => {
                  goto("/Exchange");
                }}
              />
            </span>
            <Button
              disabled={balances[asset.code] === 0 || asset.disabledSend}
              name={"Send"}
              icon={"arrowUp"}
              onClick={() => {
                modal.pushWithLoading(
                  "Send Safe " + asset.name,
                  <SendAddress asset={asset} />
                );
              }}
            />
            <Button
              name={"Recieve"}
              icon={"arrowDown"}
              onClick={() => {
                modal.pushWithLoading(
                  "Receive " + asset.name,
                  <ReceiveModal asset={asset} />
                );
              }}
            />{" "}
          </div>
        </div>
      </div>

      <div className="sm:p-8 sm:flex  justify-between sm:space-x-4">
        <div className="w-full space-y-6">
          {/* <XyChart /> */}
          <WalletAssets selected={id} />
        </div>
        <div className="w-full sm:max-w-sm space-y-6">
          {/* <Trade /> */}
          <Recent />
        </div>
      </div>
    </div>
  );
};

export default AssetPage;
