import useFetchSignature from "../hooks/useFetchSignature";

function Component() {
  const [list] = useFetchSignature("/getFunds");

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Wallet Address
            </th>
            <th scope="col" className="px-6 py-3">
              SC balance
            </th>
            <th scope="col" className="px-6 py-3">
              MC Balance
            </th>
            <th scope="col" className="px-6 py-3">
              Fee Balance
            </th>
            <th scope="col" className="px-6 py-3">
              Transactions
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((data, index) => (
            // <TableRow key={index} data={value} />
            <tr
              key={index}
              className="border-b bg-gray-800 border-[var(--chakra-colors-gray-750)]  hover:bg-gray-600"
            >
              <th
                scope="row"
                className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {data.wallet.substring(0, 30)}...
              </th>
              <td className="px-6 py-2">{data.balance / 10 ** 9} SC</td>
              <td className="px-6 py-2">{data.balanceMc / 10 ** 9} MC</td>
              <td className="px-6 py-2">{data.fee / 10 ** 9} SC</td>
              <td className="px-6 py-2">{data.transactions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Component;
