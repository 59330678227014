import { useState } from "react";
import useVar from "../hooks/useVar";
import BackButton from "./buttons/BackButton";
import NextButton from "./buttons/NextButton";
import PreviewTranasction from "./PreviewTranasction";
import EvidencesModal from "./EvidencesModal";

const questions = [
  "Tell us the full story of the transaction?",
  "Is the other party responsive?",
  "In what channel did you use to communicate? WhatsApp, Telegram, Phone, etc.",
  "What will be the outcome that will satisfy you?",
  "Why do you think the transaction reached a dispute stage?",
  "Do you think maybe there is a chance there was a misunderstanding?",
  "If you must, What will be the color you would use to label the deal?",
  "Approximately how long it took from the first interaction until the the transaction was initiated ?",
  "Do you agree the other party deserve some type of payout? maybe a partial payout? If so, how much would you consider to payout in percentages? i.e 50% 30% 80%?",
  "Please provide a way to be communicated for more details. i.e Telegram, WhatsApp, E-mail.  (OPTIONAL)",
];

const DisputeTextModal = ({ tx, step, formData }) => {
  const [description, setDescription] = useState("");
  const { modal } = useVar();

  tx.steps ??= new Array(questions.length);

  return (
    <div className="space-y-6">
      <div></div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-blue-100)] ">
          {questions[step]}
        </div>
        <div className="h-36">
          <textarea
            type="text"
            id="description"
            autoFocus={true}
            className="h-full w-full rounded-xl text-sm opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
      border-2 border-[var(--chakra-colors-gray-750)]
     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
     focus-visible:border-[var(--border-input)] focus-visible:border-2
     transition-all"
            minLength={10}
            maxLength={1000}
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="text-xs text-gray-600 mb-5 text-right">
          ({description.length}/1000)
        </div>{" "}
      </div>
      <div className="space-y-2">
        <NextButton
          text={{ disabled: "Please write your anwser", enabled: "Next" }}
          disabled={description.length < 3}
          onClick={() => {
            tx.steps[step] = {
              question: questions[step],
              answer: description,
            };

            if (step === questions.length - 1) {
              if (tx.name) {
                modal.pushWithLoading(
                  "Action required",
                  <PreviewTranasction tx={tx} formData={formData} />
                );
              } else {
                modal.pushWithLoading(
                  "Dispute Evidences Form",
                  <EvidencesModal tx={tx} />
                );
              }
            } else {
              ++step;
              modal.push(
                "Dispute Form (" +
                  parseInt(step + 1) +
                  "/" +
                  parseInt(questions.length + 1) +
                  ")",
                <DisputeTextModal tx={tx} step={step} formData={formData} />
              );
            }
            setDescription("");
          }}
        />
        <BackButton tx={tx} />
      </div>
    </div>
  );
};

export default DisputeTextModal;
