import { CloudArrowDownIcon, StarIcon } from "@heroicons/react/20/solid";
import QRCode from "react-qr-code";
import useVar from "../hooks/useVar";

function Mobile() {
  const { secret } = useVar();

  return (
    <div className="min-h-full bg-bg text-gray-200 p-4 sm:p-10 ">
      <p className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
        Mobile app – Instant Send & Receive Crypo.
      </p>

      <div className="py-10 space-y-8 text-base leading-7 text-gray-400 ">
        <Feature
          name={"Install SafeCoin Mobile app on your phone."}
          icon={<CloudArrowDownIcon />}
          description={
            <>Download the SafeCoin Mobile app fron App Store or Google Play.</>
          }
        />

        <Feature
          name={"Scan the QR code(in login screen):"}
          icon={<StarIcon />}
          description={
            <div className=" flex justify-center max-w-xl p-6">
              <div className="p-6 bg-white rounded-lg">
                <QRCode size={300} value={secret} />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

function Feature({ name, icon, description }) {
  return (
    <div key={name} className="w-full flex space-x-4">
      <div className="h-5 w-5 text-indigo-400">{icon}</div>

      <div className="w-full">
        <div className="font-semibold text-gray-100">{name}</div>
        <div className="">{description}</div>
      </div>
    </div>
  );
}

export default Mobile;
