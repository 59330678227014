const NextButton = ({ onClick, disabled, text, error }) => {
  if (error) {
    return (
      <button
        className={`opacity-40 cursor-not-allowed w-full rounded-lg text-lg font-bold bg-[var(--chakra-colors-red-500)] py-2 `}
      >
        {text.error}
      </button>
    );
  }
  if (disabled) {
    return (
      <button
        className={`opacity-40 cursor-not-allowed w-full rounded-lg text-lg font-bold bg-[var(--chakra-colors-blue-400)] py-2 `}
      >
        {text.disabled}
      </button>
    );
  }

  return (
    <button
      onClick={() => {
        onClick();
      }}
      className={`w-full rounded-lg text-lg font-bold bg-[var(--chakra-colors-blue-500)] hover:bg-[var(--chakra-colors-blue-300)] py-2 `}
    >
      {text.enabled}
    </button>
  );
};

export default NextButton;
