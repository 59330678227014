import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { Tooltip } from "flowbite-react";
import LinkLoading from "./LinkLoading";
import icons from "../js/icons";
import useVar from "../hooks/useVar";
import { isAdmin, isAmir } from "../js/utils";
// import { BrowserView } from "react-device-detect";

const SideBarItem = ({ name, link, count, tag, mobile }) => {
  const location = useLocation();

  return (
    <li>
      <Tooltip
        className="max-md:hidden lg:hidden"
        animation="duration-1000"
        placement="right"
        arrow={false}
        content={name}
      >
        <LinkLoading
          className={`flex p-3 text-base font-semibold rounded-lg justify-center w-full text-left  "
                 ${
                   location.pathname === link
                     ? "text-white bg-[var(--chakra-colors-gray-700)] "
                     : "text-[var(--chakra-colors-gray-500)] hover:text-[var(--chakra-colors-gray-300)] hover:bg-[var(--chakra-colors-gray-750)]"
                 }
                 `}
          to={link}
        >
          <div
            className={
              location.pathname === link
                ? "text-[var(--chakra-colors-blue-200)]"
                : "text-[var(--chakra-colors-gray-500)] "
            }
          >
            <div className="w-6 h-6">
              {icons[name.split(" ")[0].toLowerCase()]}
            </div>
          </div>
          <div
            className={`${mobile ? "block" : "hidden"} flex-1 ml-4 lg:block`}
          >
            {name}
          </div>
          {count > 0 && (
            <span
              className={`${
                mobile ? "inline-flex" : "hidden"
              } lg:inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium 
               rounded-full bg-cyan-400 text-cyan-800`}
            >
              {count}
            </span>
          )}
          {tag === "pro" && (
            <span
              className={`${
                mobile ? "inline-flex" : "hidden"
              }  lg:inline-flex items-center justify-center px-2  text-sm font-medium text-rose-800 bg-rose-100 rounded-md dark:bg-red-800 dark:text-rose-200`}
            >
              Pro
            </span>
          )}

          {tag === "new" && (
            <span
              className={`${
                mobile ? "inline-flex" : "hidden"
              } lg:inline-flex items-center justify-center px-2  text-sm font-medium text-rose-800 bg-rose-100 rounded-full dark:bg-yellow-800 dark:text-yellow-200`}
            >
              New
            </span>
          )}
        </LinkLoading>
      </Tooltip>
    </li>
  );
};

const WalletItems = ({ mobile }) => {
  const { historyCount, walletAddress, ruling } = useVar();

  // const inboxCount = useMemo(() => {
  //   return inbox.filter((doc) => !doc.read).length;
  // }, [inbox]);

  return (
    <div>
      <SideBarItem name={"My Wallet"} link={"/Dashboard"} mobile={mobile} />
      <SideBarItem name={"Assets"} link={"/Assets/0"} mobile={mobile} />{" "}
      <SideBarItem
        name={"Transactions History"}
        link={"/History"}
        count={historyCount}
        mobile={mobile}
      />{" "}
      <SideBarItem name={"Trade"} link={"/Trade"} mobile={mobile} />
      {/* <SideBarItem
        name={"Inbox"}
        link={"/Inbox"}
        count={inboxCount}
        mobile={mobile}
      /> */}
      {isAdmin(walletAddress) && (
        <SideBarItem
          name={"Ruling"}
          link={"/Ruling"}
          count={ruling.length}
          mobile={mobile}
        />
      )}
      {isAmir(walletAddress) && (
        <>
          <SideBarItem name={"Users"} link={"/Users"} mobile={mobile} />
          <SideBarItem name={"Funds"} link={"/Funds"} mobile={mobile} />
        </>
      )}
    </div>
  );
};

const ExplorerItems = ({ mobile }) => {
  return (
    <div>
      <SideBarItem
        name={"Mining"}
        link={"/Mining"}
        tag={"pro"}
        mobile={mobile}
      />

      <SideBarItem name={"Marchant Hub"} link={"/Marchant"} mobile={mobile} />
      <SideBarItem name={"Mobile App"} link={"/MobileQR"} mobile={mobile} />
    </div>
  );
};
const SideBarSection = ({ name, items }) => {
  return (
    <div className="px-4 py-6 space-y-2">
      <div className="uppercase text-[var(--chakra-colors-gray-500)] font-bold text-xs tracking-wider">
        {name}
      </div>
      <ul className="space-y-2">{items}</ul>
    </div>
  );
};

const CTA = () => {
  const ctaRef = useRef();

  return (
    <div
      ref={ctaRef}
      id="dropdown-cta"
      className="hidden lg:block p-4 mt-6 rounded-lg bg-blue-50 dark:bg-blue-900"
      // role="alert"
    >
      <div className="flex items-center mb-3">
        <span className="bg-orange-100 text-orange-800 text-sm  font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">
          Beta
        </span>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-900 rounded-lg focus:ring-2 
              focus:ring-blue-300 p-1 hover:bg-blue-300 inline-flex h-6 w-6 dark:bg-blue-900 
              dark:text-[var(--chakra-colors-blue-200)] dark:hover:bg-blue-800 duration-1000"
          // data-dismiss-target="#dropdown-cta"
          aria-label="Close"
          onClick={() => {
            document.getElementById("dropdown-cta").remove();
          }}
        >
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <p className="mb-3 text-sm text-blue-800 dark:text-[var(--chakra-colors-blue-200)]">
        Marchant Hub allows you to except SafeCoin on your website. Make users
        feel Safe paying with crypto on everything.
      </p>
      <a
        className="text-sm text-blue-800 underline hover:text-blue-900 dark:text-[var(--chakra-colors-blue-200)] dark:hover:text-[var(--chakra-colors-blue-200)]"
        href="http://app.safecoin.ai"
      >
        Learn more Here
      </a>
    </div>
  );
};

const SideBar = ({ mobile }) => {
  return (
    <div
      className="h-full py-2 overflow-y-auto border-r bg-bg border-[var(--chakra-colors-gray-750)] 
    transition-transform divide-y divide-[var(--chakra-colors-gray-750)] space-y-2
    "
    >
      <SideBarSection name={"WALLET"} items={<WalletItems mobile={mobile} />} />

      {/* <BrowserView> */}
      <SideBarSection
        name={"EXPLORE"}
        items={<ExplorerItems mobile={mobile} />}
      />
      {/* </BrowserView> */}

      <CTA />
    </div>
  );
};

export default SideBar;
