import useVar from "../hooks/useVar";
import { CiWarning } from "react-icons/ci";
import SendAmount from "./SendAmount";
import CancelButton from "./buttons/CancelButton";
import NextButton from "./buttons/NextButton";

export default function AttentionUnsafeSendingModal({ tx }) {
  const { modal } = useVar();

  return (
    <div className="space-y-6">
      <div className="flex space-x-2 text-base  text-yellow-200 ">
        <div className="">
          <CiWarning size={58} />
        </div>
        <div className="">
          We have noticed you are going to send funds to unsafe address. we
          recommand you to send funds to safe addesses only. If you are sure you
          want to send funds to this address, click continue.
        </div>
      </div>
      <div className="space-y-2">
        <NextButton
          text={{
            enabled: "Continue",
          }}
          onClick={() => {
            modal.push("Send Safe " + tx.asset.name, <SendAmount tx={tx} />);
          }}
        />
        <CancelButton />
      </div>
    </div>
  );
}
