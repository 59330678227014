import useFetchSignature from "../hooks/useFetchSignature";
import moment from "moment";
import ClipboardButton from "../components/ClipboardButton";

function Component() {
  const [list] = useFetchSignature("/getUsers");
  console.log("list", list);
  console.log(list);
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Wallet Address
            </th>
            <th scope="col" className="px-6 py-3">
              Signature
            </th>
            <th scope="col" className="px-6 py-3">
              MetaMask Address
            </th>
            <th scope="col" className="px-6 py-3">
              Secret
            </th>
            <th scope="col" className="px-6 py-3">
              created
            </th>
            {/* <th scope="col" className="px-6 py-3">
              verified
            </th>{" "}
            <th scope="col" className="px-6 py-3">
              refferal
            </th> */}
          </tr>
        </thead>
        <tbody>
          {list?.map((data, index) => (
            // <TableRow key={index} data={value} />
            <tr
              key={index}
              className="border-b bg-gray-800 border-[var(--chakra-colors-gray-750)]  hover:bg-gray-600"
            >
              {/* <th
                scope="row"
                className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {data.}
              </th> */}
              {/* <td className="px-6 py-2">{data.email}</td> */}

              <td className="px-6 py-2">
                <ClipboardButton address={data.walletAddress} />
              </td>
              <td className="px-6 py-2">
                <ClipboardButton address={data.signature} />{" "}
              </td>
              <td className="px-6 py-2">
                <ClipboardButton address={data.metaMaskAddress} />{" "}
              </td>
              <td className="px-6 py-2">
                <ClipboardButton address={data.secret} />{" "}
              </td>
              <td className="px-6 py-2"> {moment(data.created).fromNow()}</td>
              {/* <td className="px-6 py-2">{data.verified ? "YES" : ""}</td> */}
              {/* <td className="px-6 py-2">{data.refferal}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Component;
