import { useMemo, useState } from "react";
import useVar from "../hooks/useVar";
import useGas from "../hooks/useGas";
import MultiButton from "./buttons/MultiButton";
import { shortenAddress } from "../js/utils";
import useCreateTransaction from "../hooks/useCreateTransaction";

const colors = [
  "text-[var(--chakra-colors-yellow-200)] focus:ring-0  focus:border-0 ",
  "text-[var(--chakra-colors-blue-200)] focus:ring-0   focus:border-0 ",
  "text-[var(--chakra-colors-green-200)] focus:ring-0  focus:border-0 ",
];
const names = ["Slow", "Avreage", "Fast"];
const fees = [-1.5, 0, 1.5];

const SendGas = ({ tx }) => {
  const { modal, receivedMessage } = useVar();
  const [selectedValue, setSelectedValue] = useState(1);

  // console.log(tx);
  const gas = useGas();

  const { createInternalTransaction, createTransactionByProvider } = useCreateTransaction(modal.addState);
  const total = useMemo(() => {
    return (tx.amountUSD + parseFloat(gas) + fees[selectedValue]).toFixed(2);
  }, [tx, gas, selectedValue]);

  const onSubmit = async (e) => {
    e.preventDefault();

    await modal.addState("pending", "Connecting to Blockchain...");
    await modal.addState("pending", "Connected.");

    tx.asset.code === "sc" ? createInternalTransaction(tx) : createTransactionByProvider(tx);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="text-4xl font-bold text-gray-100  text-center">
          {tx.amount.toFixed(2)} {tx.asset.name}
        </div>
        <div className="text-xl text-[var(--chakra-colors-gray-500)] text-center">
          $ {tx.amountUSD.toFixed(2)}
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between bg-gray-800 rounded-xl w-full py-1.5 px-3">
          <div className="text-sm font-bold text-[var(--chakra-colors-gray-500)] ">
            Destination Address
          </div>
          <div className="text-sm font-normal text-[var(--chakra-colors-gray-600)] ">
            {shortenAddress(tx.recipient)}
          </div>
        </div>{" "}
      </div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Transaction Fee
        </div>

        <div className="p-2 rounded-xl border border-[var(--chakra-colors-gray-750)] bg-[var(--chakra-colors-gray-850)] flex justify-between space-x-4">
          <Fee
            value={0}
            gas={gas}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Fee
            value={1}
            gas={gas}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Fee
            value={2}
            gas={gas}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
        </div>
      </div>

      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Description
        </div>

        <div
          className="text-sm font-normal text-[var(--chakra-colors-gray-600)] 
         p-2 rounded-xl border border-[var(--chakra-colors-gray-750)] bg-[var(--chakra-colors-gray-850)] flex justify-between space-x-4"
        >
          {tx.payload.description}
        </div>
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="space-y-2">
          <div className="text-sm font-medium flex justify-between text-[var(--chakra-colors-gray-500)] ">
            <div className="">Amount + Transaction Fee</div>
            <div className="">
              {tx.amount.toFixed(2)} {tx.asset.name} +{" "}
              {(parseFloat(gas) + fees[selectedValue]).toFixed(2)} $
            </div>
          </div>{" "}
          <div className="flex justify-between text-gray-100 font-semibold">
            <div className="">Total</div>
            <div className="">$ {total}</div>
          </div>
        </div>
        <div className="space-y-2">
          <MultiButton state={receivedMessage} />
        </div>
      </form>
    </div>
  );
};

const Fee = ({ value, setSelectedValue, selectedValue, gas }) => {
  const color = colors[value];
  const name = names[value];
  const fee = Math.max(0.9, (parseFloat(gas) + fees[value]).toFixed(2));

  return (
    <label
      htmlFor={name}
      className={`
      
      ${
        parseInt(selectedValue) === value
          ? " bg-[var(--chakra-colors-gray-700)] text-[var(--chakra-colors-gray-100)]"
          : " text-[var(--chakra-colors-gray-500)] hover:text-[var(--chakra-colors-gray-300)] hover:bg-[var(--chakra-colors-gray-750)] "
      }
      w-full px-2 py-1 group  rounded-xl
       space-x-2 cursor-pointer
     `}
    >
      <input
        type="radio"
        name="radio"
        id={name}
        value={value}
        checked={parseInt(selectedValue) === value}
        onChange={(event) => {
          setSelectedValue(event.target.value);
        }}
        className={color + "bg-[var(--chakra-colors-gray-900)]"}
      />
      <span className={``}>{name}</span>
      <div className="">{fee}$</div>
    </label>
  );
};
export default SendGas;
