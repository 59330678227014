import { useMemo } from "react";
import useVar from "../hooks/useVar";
import { ethers } from "ethers";
import NextButton from "./buttons/NextButton";
import ScWalletCreateDone from "./ScWalletCreateDone";

//thumb nephew  post again gasp coin remind grain scare toast harsh burden
const ScWalletMenu = () => {
  const { modal } = useVar();

  const wallet = useMemo(() => {
    const wallet = ethers.Wallet.createRandom();

    return {
      signMessage: wallet.signMessage.bind(wallet),
      private_key: wallet.privateKey.toLowerCase(),
      public_key: wallet.address.toLowerCase(),
      mnemonic: wallet.mnemonic.phrase.split(" "),
    };
  }, []);

  return (
    <div
      className="md:p-4 space-y-10"
    >
      <div className="space-y-2 space-x-4">
        <div className="text-base text-gray-400">
          Write these 12 words down and store them securely offline. This 12
          word phrase is used to recover your wallet private keys.
        </div>
      </div>
      <div className="space-y-2 ">
        <div className="grid grid-cols-3 gap-4">
          {wallet.mnemonic.map((item, index) => {
            return (
              <div
                key={index}
                className="text-[var(--chakra-colors-blue-200)] px-1 py-1 flex space-x-2 bg-indigo-400 bg-opacity-40 rounded-md "
              >
                <div className="bg-gray-700 text-xs rounded-md text-white px-1.5 sm:leading-6 ">
                  {index + 1}
                </div>
                <div className="max-sm:text-xs">{item}</div>
              </div>
            );
          })}
        </div>
      </div>

      <NextButton
        text={{
          enabled: "Next",
        }}
        onClick={() => {
          modal.push(
            "Your Wallet is Self-Custody",
            <ScWalletCreateDone wallet={wallet} />
          );
        }}
      />
    </div>
  );
};

export default ScWalletMenu;
