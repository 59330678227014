import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Provider from "./js/Provider";

import NotFound from "./pages/NotFound";
import Mining from "./pages/Mining";
import Dashboard from "./pages/Dashboard";
import History from "./pages/History";
import Ruling from "./pages/Ruling";
import Assets from "./pages/Assets";
import Trade from "./pages/Trade";
import Splash from "./pages/Splash";
import Exchange from "./pages/Exchange";
// import Redirect from "./pages/Redirect";
import Toaster from "./components/Toaster";
import RequireAuth from "./components/RequireAuth";

import Funds from "./pages/Funds";
import Users from "./pages/Users";
// import Login from "./pages/Login";
// import SignUp from "./pages/SignUp";
import MarchantHub from "./pages/MarchantHub";
import MobileQR from "./pages/MobileQR";
import Pay from "./pages/Pay";
import ResultPage from "./pages/ResultPage";
import PayBy from "./pages/PayBy";

import BuyModal from "./modals/BuyModal";
import GenericModal from "./modals/GenericModal";
import LoadingModal from "./modals/LoadingModal";
import VerifySignOut from "./components/VerifySignOut";
import Inbox from "./components/Inbox/Inbox";
import { BrowserView } from "react-device-detect";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/ResultPage/:token" element={<ResultPage />} />
      <Route path="/Pay/:encoded" element={<Pay />} />
      <Route path="/PayBy" element={<PayBy />} />
      {/* <Route path="/Redirect" element={<Redirect />} /> */}
      <Route element={<VerifySignOut />}>
        <Route path="/Splash" element={<Splash />} />
        {/* <Route path="/Login" element={<Login />} /> */}
        {/* <Route path="/SignUp" element={<SignUp />} /> */}
      </Route>
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Funds" element={<Funds />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/History" element={<History />} />
        <Route path="/Ruling" element={<Ruling />} />
        <Route path="/Assets/:id" element={<Assets />} />
        <Route path="/Trade" element={<Trade />} />
        <Route path="/Exchange" element={<Exchange />} />
        <Route path="/Mining" element={<Mining />} />
        <Route path="/Marchant" element={<MarchantHub />} />
        <Route path="/MobileQR" element={<MobileQR />} />
        <Route path="/Inbox" element={<Inbox />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <div id="main" className="font-apple md:font-inter">
      <Router>
        <BrowserView>
          <Toaster />
        </BrowserView>
        <Provider>
          <BuyModal />
          <GenericModal />
          <LoadingModal />
          <AppRoutes />
        </Provider>
      </Router>
    </div>
  );
};

export default App;
