import svg_usdt from "../assets/coins/tether.png";
import svg_usdc from "../assets/coins/usdc.svg";
// import svg_usdt from "../assets/coins/usdt.svg";
import svg_sc from "../assets/coins/sc.svg";
// import svg_mc from "../assets/coins/mc.svg";
import etherPng from "../assets/coins/ether.png";

const namesIcons = {};
namesIcons["USDT"] = svg_usdt;
namesIcons["USDC"] = svg_usdc;
namesIcons["ETH"] = etherPng;

const chainsIcons = {};
chainsIcons["Ethereum"] = etherPng;
chainsIcons["SafeChain"] = svg_sc;

const AssetIcon = ({ asset }) => {
  return (
    <div className="relative w-8 h-8 ">
      <img className="w-full rounded-full" src={namesIcons[asset.name]} alt="" />
      <span className="-top-1 left-6 absolute w-5 h-5 border-2 border-gray-200 border-opacity-60 rounded-full">
        <img src={chainsIcons[asset.chain]} alt="" />
      </span>
    </div>
  );
};

const Assets = [
  // {
  //   code: "mc",
  //   name: "MC",
  //   long: "Mine Coin on SafeChain (MC)",
  //   chain: "SafeChain",
  //   img: (
  //     <div className="relative w-8 h-8">
  //       <img className="rounded-full" src={svg_mc} alt="" />
  //       <span className="-top-1 left-6 absolute w-5 h-5 border-2 border-gray-200 border-opacity-60 rounded-full">
  //         <img src={svg_sc} alt="" />
  //       </span>
  //     </div>
  //   ),
  // },
  {
    code: "usdt",
    name: "USDT",
    long: "Teter on Ethereum (USDT)",
    chain: "Ethereum",
  },

  {
    code: "usdc",
    name: "USDC",
    disabledSend: true,
    chain: "Ethereum",
    long: "USD Coin on Ethereum (USDC)",
  },
  {
    code: "eth",
    name: "ETH",
    disabledSend: true,
    chain: "Ethereum",
    long: "Ethereum (ETH)",
  },
  {
    code: "sc",
    name: "USDT",
    long: "Teter on SafeChain (USDT)",
    chain: "SafeChain",
  },
];

export { Assets, AssetIcon };
