// import ScImg from "../assets/coins/sc.svg";
import useVar from "../hooks/useVar";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailedHistory from "./DetailedHistory";
import LinkLoading from "./LinkLoading";
import { Assets, AssetIcon } from "../js/Assets";

const RecentItem = ({ date, item }) => {
  const [open, setOpen] = useState(false);
  const { exchangeRates } = useVar();

  return (
    <div
      onClick={() => {
        setOpen(!open);
      }}
      className={`
      ${
        open &&
        `bg-[var(--chakra-colors-gray-750)] rounded-lg border-opacity-20 border-gray-400 border
         divide-y divide-gray-400 divide-opacity-20`
      } 
      w-full text-sm  my-1 hover:rounded-lg 
       hover:bg-[var(--chakra-colors-gray-750)] duration-500 space-y-2 transition-opacity  cursor-pointer
                 `}
    >
      <div className="p-4 space-y-2">
        <div className="flex justify-between items-center">
          <div className="flex space-x-2 items-center justify-start">
            <div className="text-base font-bold rounded-full bg-[var(--chakra-colors-whiteAlpha-200)] p-1 h-6 w-6 shadow-md">
              {item.icon}
            </div>
            <div className="leading-3  text-sm text-white font-bold ">
              {item.status.split(" ")[0]}
            </div>
          </div>
          <div className="text-[var(--chakra-colors-gray-500)] text-sm font-normal">
            {date} {item.hours}
          </div>
        </div>
        <div className=" flex items-center space-x-4">
          
          <AssetIcon asset={Assets[item.index]}/>
          <div className="">
            <div className="text-sm text-gray-200 font-medium  leading-5">
              {item.amount.toFixed(2)} {Assets[item.index].name}
            </div>
            <div className="text-[var(--chakra-colors-gray-500)] text-sm font-normal">
              $ {(exchangeRates[item.code] * item.amount).toFixed(2)}
            </div>
          </div>
        </div>{" "}
      </div>

      <DetailedHistory show={open} item={item} />
    </div>
  );
};

const Recent = () => {
  const { history } = useVar();

  const [list, setList] = useState([]);

  useEffect(() => {
    const a = [];
    history.forEach(({ date, list }) => {
      list.forEach((item) => {
        a.push({ date, item });
      });
    });

    setList(a.slice(0, 5));
  }, [history]);

  return (
    <div className="p-3 w-full bg-[var(--chakra-colors-gray-785)] border border-gray-800 sm:rounded-xl shadow-md">
      <div className="flex justify-between px-5 py-4">
        <div className="text-base font-bold text-gray-100">
          Recent Transactions
        </div>
        <LinkLoading
          to="/History"
          className="text-md font-semibold text-indigo-400  hover:underline"
        >
          View All
        </LinkLoading>
      </div>
      {list.map(({ date, item }, index) => (
        <RecentItem key={index} date={date} item={item} />
      ))}
    </div>
  );
};

export default Recent;
