// import { useEffect } from "react";
import useVar from "../hooks/useVar";
import useProviderMetaMask from "../hooks/useProviderMetaMask";
import useSelfCustody from "../hooks/useSelfCustody";
import svg from "../assets/coins/sc_bw.svg";
import MetaMaskIcon from "../assets/MetaMaskIcon1.svg";

import { BrowserView } from "react-device-detect";
import Canvas from "../components/Canvas";
import { useNavigate } from "react-router-dom";

// function isMetaMask(userAgent) {
//   // Regular expressions to match common mobile user agents
//   const mobileRegex = /metamaskmobile/i;
//   return mobileRegex.test(userAgent);
// }

const MetaMaskButton = () => {
  const { connect } = useProviderMetaMask();

  const { setIsLoading } = useVar();
  const navigate = useNavigate();

  return (
    <button
      onClick={async () => {
        setIsLoading(true);

        await connect();

        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 500);
      }}
      className={`py-2 px-6 rounded-lg transition-all duration-500 
      bg-[var(--chakra-colors-blue-400)] hover:bg-[var(--chakra-colors-blue-300)]  active:bg-[var(--chakra-colors-blue-500)]`}
      //    border-[var(--chakra-colors-blue-400)] hover:bg-transparent border-2 capitalize
    >
      <div className="flex space-x-4 justify-center items-center">
        <img className="w-8 h-8" src={MetaMaskIcon} alt="" />
        <div className="text-lg font-semibold">Connect MetaMask</div>
      </div>
    </button>
  );
};

const SelfWalletButton = () => {
  const { onClick } = useSelfCustody();

  return (
    <button
      onClick={onClick}
      className={`py-2 px-6 rounded-lg transition-all duration-500 
      bg-[var(--chakra-colors-blue-400)] hover:bg-[var(--chakra-colors-blue-300)]  active:bg-[var(--chakra-colors-blue-500)]`}
      //    border-[var(--chakra-colors-blue-400)] hover:bg-transparent border-2 capitalize
    >
      <div className="flex space-x-4 justify-center items-center">
        <img className="w-8 h-8" src={svg} alt="" />
        <div className="text-lg font-semibold">Connect SafeCoin Wallet</div>
      </div>
    </button>
  );
};
const Splash = () => {
  // useEffect(() => {
  //   if (
  //     isMobile(window.navigator.userAgent) &&
  //     !isMetaMask(window.navigator.userAgent)
  //   ) {
  //     // window.location.href = "https://metamask.app.link/dapp/app.safecoin.ai";
  //   }
  // }, []);

  return (
    <section className="bg-bgMain bg-cover bg-bottom flex flex-col justify-center items-center h-screen p-5 ">
      <Canvas />

      <div
        className="
        "
        // rounded-2xl  p-10
        // shadow-2xl shadow-indigo-300 bg-black z-20 bg-opacity-50
        // flex items-center justify-between flex-col min-h-[600px] sm:min-w-[400px]
      >
        <div className="relative flex items-center justify-center flex-col space-y-6 text-center">
          <img src={svg} loading="lazy" alt="" className="h-14 sm:h-20" />
          <div className="text-6xl sm:text-8xl font-light tracking-tight text-gray-100 ">
            Welcome to
            <span className="font-bold"> SafeCoin</span>
          </div>

          <div className="text-xl sm:text-2xl font-light tracking-tight text-gray-100 ">
            Best Crypto in the world 100% best fees, best speed, best security
          </div>

          <div className="pt-6 sm:flex sm:justify-around w-full max-w-4xl max-sm:space-y-4">
            <SelfWalletButton />
            <BrowserView>
              <MetaMaskButton />
            </BrowserView>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Splash;
