import { useState, useEffect, useCallback } from "react";
import { sleep } from "../js/utils";

const useModal = (receivedMessage, setReceivedMessage, runLoading) => {
  const [list, setList] = useState([{ show: false }]);
  const [timer, setTimer] = useState(undefined);

  const addState = useCallback(
    async (type, text) => {
      setReceivedMessage({
        type,
        text,
      });
      await sleep(type === "error" ? 4000 : 3000);
    },
    [setReceivedMessage]
  );

  const push = useCallback(
    (title, body) => {
      addState("action", "Confirm");

      setList([
        ...list,
        {
          show: true,
          title,
          body,
        },
      ]);
    },
    [addState, list]
  );

  const pushWithLoading = useCallback(
    (title, body) => {
      runLoading(() => push(title, body));
    },
    [push, runLoading]
  );
  const pop = useCallback(() => {
    addState("action", "Confirm");

    list.pop();
    setList([...list]);
  }, [addState, list]);

  const close = useCallback(() => {
    list.at(-1).show = false;
    setList([list.at(-1)]);
  }, [list]);

  useEffect(() => {
    if (list.at(-1).show === false && timer) {
      clearInterval(timer);
      setTimer(undefined);
    }
  }, [list, timer]);

  useEffect(() => {
    if (receivedMessage?.text === "Closing in 5 seconds...") {
      let time = 4;
      const timer = setInterval(() => {
        addState(receivedMessage.type, "Closing in " + time + " seconds...");

        if (time-- === 0) {
          close();
        }
      }, 1000);

      setTimer(timer);
    }
  }, [receivedMessage, close, addState]);

  return {
    pop,
    push,
    pushWithLoading,
    close,
    list,
    addState,
  };
};

export default useModal;
