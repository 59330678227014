import { Assets, AssetIcon } from "../js/Assets";
import useVar from "../hooks/useVar";
import useNetWorth from "../hooks/useNetWorth";

import { useLocation, useNavigate } from "react-router-dom";

const WalletAssets = ({ selected }) => {
  return (
    <div className="p-3 w-full  bg-[var(--chakra-colors-gray-785)] border border-gray-800 sm:rounded-xl shadow-md ">
      <div className="px-5 py-4 text-base font-bold text-gray-100">
        Wallet Assets
      </div>

      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left">
          <thead className="text-xs  uppercase text-[var(--chakra-colors-gray-500)]">
            <tr>
              <th scope="col" className="px-2 sm:px-4 py-3">
                ASSET
              </th>
              <th scope="col" className="px-2 sm:px-4 py-3">
                BALANCE
              </th>
              <th scope="col" className="px-2 sm:px-4 py-3">
                USD EX. RATE
              </th>
              {/* <th scope="col" className="hidden px-2 sm:px-4 py-3">
                PRICE CHANGE
              </th> */}
              <th scope="col" className="px-2 sm:px-4 py-3">
                HOLDING
              </th>
            </tr>
          </thead>
          <tbody>
            {Assets.map((item, index) => (
              <AssetItem
                key={index}
                item={item}
                index={index}
                selected={selected}
              />
              // </LinkLoading>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AssetItem = (props) => {
  const { item, index, selected } = props;
  const { balances, exchangeRates } = useVar();
  const { netWorth } = useNetWorth();
  const { runLoading } = useVar();
  const location = useLocation();
  const navigate = useNavigate();

  if (!exchangeRates[item.code]) return <></>;

  return (
    <tr
      onClick={() => {
        if (location.pathname === `/Assets/${index}`) return;

        runLoading(() => {
          navigate(`/Assets/${index}`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
      }}
      className={` ${
        parseInt(selected) === parseInt(index)
          ? "bg-[var(--chakra-colors-gray-700)] "
          : "hover:bg-[var(--chakra-colors-gray-750)] "
      } border-[var(--chakra-colors-gray-750)] cursor-pointer border-y text-gray-100`}
    >
      <td className="px-4 py-6">
        <div className="flex space-x-4 items-center font-medium">
          <AssetIcon asset={item} />

          <div>
            <div className="sm:text-base text-white ">{item.name} On </div>
            <div className="text-[var(--chakra-colors-gray-500)]">
              {item.chain}
            </div>
          </div>
        </div>
      </td>
      <td className="px-2 sm:px-4 py-2 ">
        <div className="font-medium">
          <div className="sm:text-base  text-white tracking-wide">
            ${" "}
            {((balances[item.code] ?? 0) * exchangeRates[item.code]).toFixed(2)}
          </div>
          <div className="text-white">
            {balances[item.code]?.toFixed(3)} {item.name}
          </div>
        </div>
      </td>
      <td className="px-2 sm:px-4 py-2 sm:text-base ">
        {exchangeRates[item.code]?.toFixed(2)}{" "}
      </td>
      {/* <td className={`hidden px-2 sm:px-4 py-2`}>
        <div
          className={`flex items-center space-x-2 sm:text-base font-medium  ${
            item.exchange_rate_change >= 0
              ? "text-green-500 "
              : " text-red-500 "
          } `}
        >
          <div className={`${item.exchange_rate_change < 0 && "rotate-180"}`}>
            {triangle}
          </div>
          <div>{item.exchange_rate_change} %</div>
        </div>
      </td> */}
      <td className="px-2 sm:px-4 py-2 text-[var(--chakra-colors-gray-500)] font-medium sm:text-base text-xs">
        {(
          ((balances[item.code] ?? 0) * exchangeRates[item.code] * 100) /
          netWorth
        ).toFixed(1)}{" "}
        %
      </td>
    </tr>
  );
};

export default WalletAssets;
