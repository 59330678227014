import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

// import ScImg from "../assets/coins/sc.svg";
import ScImg from "../assets/coins/sc_bw.svg";

import useVar from "../hooks/useVar";

const naming = {
  btc: "bitcoin",
  eth: "ethereum",
  usdterc20: "tether",
  usdc: "usdc",
};

export default function ModalComp() {
  const { openBuyModal, setOpenBuyModal } = useVar();

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={openBuyModal !== undefined} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpenBuyModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                <div className="bg-gradient-to-t from from-gray-800 to-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                      <img src={ScImg} alt="sc" className="h-10 mr-2 " />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-100 underline"
                      >
                        Buying {openBuyModal?.metadata.amount / 10 ** 9}{" "}
                        SafeCoins
                      </Dialog.Title>
                      <div className="mt-5">
                        <p className="text-sm text-gray-400 flex">
                          Please transfer{" "}
                          <span className="mx-1 text-gray-300 flex">
                            {
                              openBuyModal?.pricing[
                                naming[openBuyModal?.symbol]
                              ].amount
                            }
                            <img
                              src={openBuyModal?.icon}
                              alt="sc"
                              className="h-4 mx-1"
                            />
                          </span>{" "}
                          to the following address:
                        </p>
                        <p className="text-lg text-gray-200 mt-1">
                          {
                            // openBuyModal?.addresses[
                            //   naming[openBuyModal?.symbol]
                            // ]

                            "0xA603eA61a28Ca52a46aCe048188Cb4D2D0A89678".toLowerCase()
                            // "0xF85C9e429C5d99808806e5c482a4854311b49C6A".toLowerCase()
                          }
                        </p>
                        <p className="mt-6 text-sm text-gray-400">
                          After the transaction will be confirmed, you will
                          receive the {openBuyModal?.metadata?.amount / 10 ** 9}{" "}
                          SC to this acoount.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {/* <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenBuyModal(undefined)}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenBuyModal(undefined)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button> */}

                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => setOpenBuyModal(undefined)}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
