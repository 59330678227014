import useVar from "../hooks/useVar";
import { Assets, AssetIcon } from "../js/Assets";

import { useMemo, useState, useCallback } from "react";
import icons from "../js/icons";
import useGas from "../hooks/useGas";
import PreviewTrade from "../modals/PreviewTrade";
import MultiButton from "../modals/buttons/MultiButton";
import TradeComponent from "./TradeComponent";

const Trade = () => {
  const { exchangeRates, modal } = useVar();
  const [from, setFrom] = useState(Assets[0]);
  const [to, setTo] = useState(Assets[3]);
  const [amountPay, setAmountPay] = useState("");

  const gas = useGas();

  const ratio = useMemo(() => {
    if (!exchangeRates[from.code] || !exchangeRates[to.code]) return;

    return exchangeRates[from.code] / exchangeRates[to.code];
  }, [from, to, exchangeRates]);

  const amountGet = useMemo(() => {
    if (!exchangeRates[from.code] || !exchangeRates[to.code]) return 0;
    // const usdPay = amountPay * exchangeRates[from.code];

    return amountPay;
  }, [amountPay, from, to, exchangeRates]);

  const isEnabled = useCallback(() => {
    return (
      ((from.code === "usdt" || from.code === "usdc") &&
        to.code === "sc" &&
        amountPay > 0) ||
      (from.code === "sc" &&
        amountPay > 0 &&
        (to.code === "usdt" || to.code === "usdc"))
    );
  }, [amountPay, from, to]);

  const onSubmit = async (e) => {
    e.preventDefault();

    modal.pushWithLoading(
      "Trade",
      <PreviewTrade trade={{ amountPay, to, from, setAmountPay }} />
    );
  };

  return (
    <div className="px-6 py-4 w-full bg-[var(--chakra-colors-gray-785)] border border-gray-800 sm:rounded-xl shadow-md">
      <TradeComponent from={from} setFrom={setFrom} to={to} setTo={setTo} />
      <form onSubmit={onSubmit}>
        <div className="space-y-4 mt-4">
          <YouBlock
            name="Pay"
            asset={from}
            amount={amountPay}
            setAmount={setAmountPay}
            gas={gas}
          />
          <YouBlock name="Get" gas={gas} asset={to} amount={amountGet} />
        </div>

        <div className="mt-8 border-[var(--chakra-colors-gray-750)] border p-4 rounded-xl space-y-2 ">
          <div className=" flex items-center justify-between">
            <div className="text-white text-sm">
              1 {from.name} = {ratio} {to.name}
            </div>

            <div className="flex items-center justify-center space-x-2 ">
              <div className="w-3.5 h-3.5 text-gray-400">{icons["gas"]}</div>
              <div className="text-gray-200 text-sm">{gas} USDT</div>
            </div>
          </div>
          {/* <div className="flex items-center justify-between">
          <div className="text-gray-400 flex items-center justify-start space-x-2">
            <div className=" text-sm font-medium">Expected Amount</div>
            <ChevronDownIcon className="h-5 w-5" />
          </div>
          <div className="flex items-center justify-center space-x-2 ">
            <div className="text-gray-200 text-sm">
              {(amountGet + gas).toFixed(0)} {to.name}
            </div>
          </div>
        </div> */}
        </div>

        {/* <button className="mt-6 w-full opacity-40 cursor-not-allowed bg-[var(--chakra-colors-blue-500)] py-3 px-6 rounded-xl">
        <div className="text-gray-100 text-lg font-semibold">Preview Trade</div>
      </button> */}

        <MultiButton
          state={{
            type: isEnabled() ? "action" : "disabled",
            text: "Preview Trade",
          }}
        />
      </form>
    </div>
  );
};

const YouBlock = ({ name, asset, amount, setAmount }) => {
  const { balances, exchangeRates, setShowBottomBar } = useVar();

  const maxAmount = useMemo(() => {
    return Math.floor(balances[asset.code]);
  }, [asset.code, balances]);

  // const minAmount = 1;

  return (
    <div
      className="w-full p-4 bg-[var(--chakra-colors-gray-800)] 
            hover:bg-[var(--chakra-colors-gray-900)] 
            rounded-2xl border border-gray-800 relative overflow-hidden shadow-md space-y-3"
    >
      <div className="flex items-center justify-between">
        <div className="text-white text-sm font-medium">You {name}</div>
        <div className="text-[var(--chakra-colors-gray-500)] text-sm font-medium">
          ≈ $ {(amount * exchangeRates[asset.code]).toFixed(0)}
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <AssetIcon asset={asset} />

        {name === "Pay" ? (
          <input
            onBlur={() => setShowBottomBar(true)}
            onFocus={() => setShowBottomBar(false)}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            // step={"any"}
            className="bg-transparent text-white border-0 outline-none focus:ring-0"
            id="amount"
            onWheel={(e) => e.target.blur()}
            // min={Math.ceil(minAmount)}
            max={Math.floor(maxAmount)}
            maxLength="10"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            placeholder="0.00"
          />
        ) : (
          <div className="text-white text-base">{amount}</div>
        )}
      </div>

      <div className=" flex justify-between items-center">
        <div className="text-[var(--chakra-colors-gray-500)] text-sm font-medium">
          Balance {balances[asset.code]?.toFixed(2)} {asset.name}
        </div>
        <div>
          {name === "Pay" && (
            <button
              type="button"
              onClick={() => setAmount(maxAmount.toFixed(2))}
              className="text-white bg-[var(--chakra-colors-gray-700)] px-2 py-1 rounded-xl
           hover:bg-[var(--chakra-colors-gray-600)] text-xs font-semibold"
            >
              Max
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Trade;
