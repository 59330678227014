import { TiTick } from "react-icons/ti";


const Item = ({ name, value }) => {
  return (
    <div className="flex space-x-6 items-center">
      <TiTick className="" />
      <div>
        <span className="text-gray-500 font-medium">{name}</span>{" "}
        <span className="text-xs overflow-y-auto">{value}</span>
      </div>
    </div>
  );
};

const Dispute = ({ body }) => {
  const { tx, initiator } = body;

  return (
    <div className="space-y-4 text-base">
      <p>Dear SafeCoin user,</p>

      <p>
        As the AI system behind SafeCoin, we have been notified of the dispute
        between you and the other party. Our objective is to facilitate a fair
        and transparent resolution of the dispute using our innovative AI
        technology. To ensure that we have a comprehensive understanding of the
        situation, we kindly request that you provide us with the following
        information as soon as possible:
      </p>
      <p>
        Any relevant documentation or correspondence relating to the dispute,
        such as contracts, invoices, emails, or other communication. Any other
        information that you believe may be relevant to the dispute.
      </p>

      <p>
        Please send the requested information to the following email address:
        <span className="font-bold italic"> dispute@safecoin.ai</span>. In the
        email, please include the following information.
      </p>
      <p>
        The following information is recorded in the blockchain and will be used
        to verify the information provided:
      </p>
      <div>
        <div className="p-2">
          <Item name="Dispute Initiator:" value={initiator} />
          <Item name="Origin Address:" value={tx.fromAddress} />
          <Item name="Destination Address:" value={tx.toAddress} />
          <Item name="Hash:" value={"0x" + tx.hash} />
          <Item name="Timestamp:" value={tx.timestamp} />
          <Item name="Description:" value={tx.payload?.description} />
          <Item name="Amount:" value={tx.payload?.amount / 10 ** 9 + "USDT"} />
        </div>
      </div>
      <p>
        Our AI technology will analyze the information provided to determine the
        best course of action for resolving the dispute. We assure you that the
        information provided will be treated with strict confidentiality and
        will be used solely for the purpose of dispute resolution. Please
        provide the requested information within 72 hours to facilitate a prompt
        resolution of the dispute. If you have any questions or concerns
        regarding this request, please do not hesitate to contact us.
      </p>
      <p className="px-2">Thank you for your cooperation in this matter.</p>
      <div>
        <b>
          <div> Sincerely,</div>
          <div> SafeCoin AI.</div>
        </b>
      </div>
    </div>
  );
};

const Ruling = ({ body }) => {
  const { tx, ruling } = body;

  return (
    <div className="space-y-4 text-base">
      <p>Dear SafeCoin user,</p>

      <p>
        We are writing to inform you of the ruling regarding the dispute that
        was initiated by the owner of the origin address, who claimed that the
        owner of the destination address failed to deliver the agreed
        development services on the app as per the attached deal. After
        receiving this notification, we requested both parties to provide any
        relevant documentation or correspondence relating to the dispute, such
        as contracts, invoices, emails, or other communication.
      </p>

      <p>
        The following information is recorded in the blockchain and will be used
        to verify the information provided:
      </p>
      <p>
        <div className="p-2">
          <Item name="Origin Address:" value={tx.fromAddress} />
          <Item name="Destination Address:" value={tx.toAddress} />
          <Item name="Hash:" value={"0x" + tx.hash} />
          <Item name="Timestamp:" value={tx.timestamp} />
          <Item name="Description:" value={tx.payload?.description} />
          <Item name="Amount:" value={tx.payload?.amount / 10 ** 9 + "USDT"} />
        </div>
      </p>
      <p>
        After a thorough review of the information provided by both parties, our
        AI technology has determined that the owner of the destination address
        did not fulfill the terms of the attached deal and failed to deliver the
        agreed development services on the app. Therefore, we rule in favor of
        the owner of the origin address.
      </p>

      <p>Ruling: ${ruling}</p>
      <p>
        Please note that this ruling is final and binding. We appreciate your
        cooperation and understanding throughout this process. If you have any
        further questions or concerns, please do not hesitate to contact us.
      </p>
      <p>Thank you for your cooperation in this matter.</p>
      <div>
        <b>
          <div> Sincerely,</div>
          <div> SafeCoin AI.</div>
        </b>
      </div>
    </div>
  );
};

const Selectror = ({ body }) => {
  return body?.type === "ruling" ? (
    <Ruling body={body} />
  ) : (
    <Dispute body={body} />
  );
};

export default Selectror;
