import svg from "../assets/coins/sc_bw.svg";
import Canvas from "../components/Canvas";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import LinkLoading from "../components/LinkLoading";

const ResultPage = () => {
  const { token } = useParams();

  const decodedToken = JSON.parse(atob(token));
  const { header, success, error, secret, link } = decodedToken;

  console.log(`ResultPage::decodedToken: `, decodedToken);
  return (
    <section className="bg-bgMain bg-cover   bg-bottom flex flex-col justify-center items-center  min-h-screen ">
      <Canvas />

      <div
        className="shadow-2xl shadow-indigo-300 bg-black z-20 bg-opacity-50 
  rounded-2xl flex items-center justify-center p-10
   "
      >
        <div className="flex items-center justify-center flex-col">
          <img src={svg} loading="lazy" alt="" className="h-16" />
          <h2 className="mt-3 text-center text-3xl font-light tracking-tight text-indigo-400 capitalize">
            {header}
          </h2>
          {error && (
            <p className="text-red-400 max-w-sm mt-3 text-center">{error}</p>
          )}
          {success && (
            <p className="text-green-400 max-w-sm mt-3 text-center">
              {success}
            </p>
          )}
          {/* {msg && (
            <p className="text-red-400 max-w-sm mt-3 text-center">{msg}</p>
          )} */}
          <div className="mt-8 space-y-6 w-full max-w-xs">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm mb-10">
              {secret && (
                <div>
                  <label htmlFor="wallet-address">Wallet Private Key: </label>
                  <input
                    autoFocus={true}
                    maxLength="60"
                    value={secret}
                    id="secret"
                    name="secret"
                    type="text"
                    disabled={true}
                    autoComplete="secret"
                    required
                    className="mt-1 relative block w-full text-white 
   bg-transparent appearance-none rounded-md border
    border-gray-300 px-3 py-2  placeholder-gray-300
     focus:z-10 focus:border-white focus:outline-none
      focus:ring-gray-300 sm:text-sm"
                    placeholder="Insert the wallet secret code here"
                  />
                </div>
              )}
            </div>

            <LinkLoading
              to={link}
              className="  mx-auto group relative flex justify-center
          rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm
          font-medium text-white hover:bg-indigo-700"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              Continue
            </LinkLoading>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultPage;
