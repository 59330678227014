import { useMemo, useEffect } from "react";

const useBalances = (account, receivedMessage, walletProvider) => {
  const balances = useMemo(() => {
    if (!account || !walletProvider.balances) {
      return {
        sc: 0,
        mc: 0,
        usdt: 0,
        usdc: 0,
        eth: 0,
      };
    }

    return {
      sc: account.balance / 10 ** 9,
      mc: account.balanceMc / 10 ** 9,
      usdt: walletProvider.balances.usdt,
      usdc: walletProvider.balances.usdc,
      eth: walletProvider.balances.eth,
    };
  }, [account, walletProvider]);

  useEffect(() => {
    if (receivedMessage === `Trade Completed`) {
      walletProvider.updateBalances();
    }
  }, [receivedMessage, walletProvider]);

  return [balances];
};

export default useBalances;
