import { useState } from "react";

import useVar from "../hooks/useVar";
import MultiButton from "./buttons/MultiButton";
import useCreateTransaction from "../hooks/useCreateTransaction";

const ModalRuling = ({ tx }) => {
  const [ruling, setRuling] = useState("");
  const { modal, receivedMessage } = useVar();
  const { createInternalTransaction } = useCreateTransaction(modal.addState);

  const onSubmit = async (e) => {
    e.preventDefault();

    await modal.addState("pending", "Connecting to Blockchain...");
    await modal.addState("pending", "Connected.");

    tx.payload.dispute.ruling = ruling;
    createInternalTransaction(tx);
  };

  return (
    <div className="space-y-6">
      <div></div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Please enter a short description of the ruling.
        </div>
        <div className="h-36">
          <textarea
            type="text"
            id="ruling"
            autoFocus={true}
            className="h-full w-full rounded-xl text-sm opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            minLength={10}
            maxLength={1000}
            rows={6}
            value={ruling}
            onChange={(e) => setRuling(e.target.value)}
            required
          />
        </div>

        <div className="text-xs text-gray-600 mb-5 text-right">
          ({ruling.length}/1000)
        </div>
      </div>

      <form onSubmit={onSubmit} className="space-y-4">
        <div className="space-y-2">
          <MultiButton state={receivedMessage} />
        </div>
      </form>
    </div>
  );
};

export default ModalRuling;
