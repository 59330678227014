import svg from "../../../assets/coins/sc_bw.svg";
import moment from "moment";
import DisputeMail from "./DisputeMail";

export default function MailBody({ msg }) {
  return (
    <div
      className="p-6 xs:p-8 bg-[var(--chakra-colors-gray-750)] xs:m-2 
          shadow shadow-[var(--chakra-colors-gray-750)] xs:rounded-lg"
    >
      <div className="flex justify-between">
        <div className="flex items-center">
          <h4 className="font-semibold text-gray-100">{msg?.from}</h4>
          <span className="pl-2 text-gray-400 font-bold">wrote</span>
        </div>
        <div className="flex items-center">
          <span className="text-sm text-gray-500 mr-4">
            {moment(msg?.created).fromNow()}
          </span>

          {/* <img src={svg} loading="lazy" alt="" className="h-14 sm:h-20" /> */}

          <img className="rounded-full w-8 h-8" src={svg} alt="" />
        </div>
      </div>
      <div className="pt-6 text-sm text-gray-300">
        <DisputeMail body={msg?.body} />
      </div>
    </div>
  );
}
