import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { FaGasPump } from "react-icons/fa";
import useVar from "../hooks/useVar";
import DisputeTextModal from "../modals/DisputeTextModal";
import { CopyToClipboardButton } from "../js/utils";

const Shorted = (address) => {
  if (!address) return <> </>;

  return (
    <CopyToClipboardButton address={address}>
      {address.slice(0, 6) + "..." + address.slice(48)}
    </CopyToClipboardButton>
  );
};

const UploadButton = ({ item }) => {
  const { modal } = useVar();

  return (
    <div className="flex space-x-2 items-center">
      <button
        onClick={() => {
          modal.pushWithLoading(
            "Dispute Form (1/11)",
            <DisputeTextModal step={0} tx={item} />
          );
        }}
        className=" bg-indigo-600 text-indigo-200 hover:bg-indigo-500 py-1 px-2 sm:px-4 rounded-md  
                                         active:translate-y-2 text-xs sm:text-base
                                        hover:shadow-md duration-500"
      >
        Upload Evidences
      </button>

      <div className="text-yellow-300"> * Action Required</div>
    </div>
  );
};

const Confirmed = ({ children }) => {
  return (
    <div
      className={`
      ${
        children === "Completed"
          ? "text-green-300 bg-green-400 "
          : "text-red-300 bg-red-400 "
      } 
      bg-opacity-20 flex px-2 py-0.5 items-center rounded-lg space-x-2`}
    >
      {children === "Completed" && (
        <svg className="h-4 w-4" viewBox="0 0 24 24" focusable="false">
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
      )}

      <div className="">{children}</div>
    </div>
  );
};

const DetailedHistory = ({ show, item }) => {
  const { walletAddress, networkName } = useVar();

  return (
    <div>
      <Transition
        // className="border border-gray-400 border-opacity-20 rounded-b-lg"
        as={Fragment}
        show={show}
        enter="transition duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave=" transition duration-300 "
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95 "
      >
        <div className={`w-full  font-medium `}>
          <div className="sm:flex sm:flex-wrap max-sm:divide-y sm:divide-x divide-gray-400 divide-opacity-20">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm px-4 py-2">Transfers</div>
                <div className="flex justify-between items-center space-x-1 px-4 py-2">
                  <div className="text-gray-500 text-sm ">{item.fee} </div>
                  <FaGasPump className=" text-gray-500 text-sm mx-auto" />
                </div>
              </div>
              <div className="p-2 bg-white bg-opacity-5 m-4 rounded-lg">
                <div className="flex justify-between px-2 py-2">
                  <div className="text-gray-400 text-sm ">From</div>
                  <div className="text-indigo-400 text-sm ">
                    {item.title === "Send"
                      ? Shorted(walletAddress)
                      : Shorted(item.address)}
                  </div>
                </div>
                <div className="flex justify-between px-2 py-2">
                  <div className="text-gray-400 text-sm ">To </div>
                  <div className="text-indigo-400 text-sm ">
                    {item.title !== "Send"
                      ? Shorted(walletAddress)
                      : Shorted(item.address)}
                  </div>
                </div>
                <div className="text-left"> </div>
              </div>
            </div>
            <div className="flex-1 ">
              <div className="flex justify-between px-4 py-2 ">
                <div className="text-gray-500 text-sm ">TX ID</div>
                <div className="text-indigo-400 text-sm ">
                  <CopyToClipboardButton address={"0x" + item.hash}>
                    0x{item.hash.slice(0, 7)}...{item.hash.slice(60)}
                  </CopyToClipboardButton>
                </div>
              </div>
              <div className="flex justify-between px-4 py-2 border-b  border-gray-400 border-opacity-20">
                <div className="text-gray-500 text-sm ">Status</div>
                <Confirmed>{item.status.split(" ")[0]}</Confirmed>
              </div>
              <div className="flex justify-between px-4 py-2">
                <div className="text-gray-500 text-sm ">Chain</div>
                <div className="text-gray-200 text-sm ">{networkName}</div>
              </div>
              <div className="flex justify-between px-4 py-2">
                <div className="text-gray-500 text-sm ">Transaction Type</div>
                <div className="text-gray-200 text-sm ">
                  {item.status !== "Mobile payment"
                    ? "2-Factor Approval"
                    : "Mobile"}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-400 border-opacity-20"> </div>{" "}
          <div>
            <div className="text-gray-500 text-sm px-2 py-2">
              Transaction Description
            </div>
            <div className="p-2">
              <div className="flex justify-between px-2 py-1">
                <div
                  className={` text-green-300 bg-green-400 bg-opacity-20 flex px-2 py-0.5 
                  items-center rounded-lg text-sm `}
                >
                  {item.description}
                </div>
              </div>
            </div>
          </div>
          {item.status.includes("Disputed by") && (
            <>
              <div className="border-b border-gray-400 border-opacity-20">
                {" "}
              </div>{" "}
              <div>
                <div className="text-gray-500 text-sm px-2 py-2">
                  Dispute Status
                </div>
                <div className="p-2 bg-white bg-opacity-5 m-4 rounded-lg">
                  <div className="flex justify-between px-2 py-1">
                    <div className="text-gray-400 text-sm space-y-4">
                      <p>
                        As the AI system behind SafeCoin, we have been notified
                        of the dispute between you and the other party. Our
                        objective is to facilitate a fair and transparent
                        resolution of the dispute using our innovative AI
                        technology. To ensure that we have a comprehensive
                        understanding of the situation, we kindly request that
                        you provide us with the following information as soon as
                        possible: Any relevant documentation or correspondence
                        relating to the dispute, such as contracts, invoices,
                        emails, or other communication. Any other information
                        that you believe may be relevant to the dispute.
                      </p>
                      <p>
                        Our AI technology will analyze the information provided
                        to determine the best course of action for resolving the
                        dispute. We assure you that the information provided
                        will be treated with strict confidentiality and will be
                        used solely for the purpose of dispute resolution.
                        Please provide the requested information within 72 hours
                        to facilitate a prompt resolution of the dispute. If you
                        have any questions or concerns regarding this request,
                        please do not hesitate to contact us.
                      </p>
                      <UploadButton item={item} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {item.dispute && (
            <>
              <div className="border-b border-gray-400 border-opacity-20">
                {" "}
              </div>{" "}
              <div>
                <div className="text-gray-500 text-sm px-2 py-2">
                  Dispute Resolved
                </div>
                <div className="p-2 bg-white bg-opacity-5 m-4 rounded-lg">
                  <div className="flex justify-between px-2 py-1">
                    <div className="text-gray-400 text-sm space-y-4">
                      <p>
                        We are writing to inform you of the ruling regarding the
                        dispute that was initiated by the owner of the origin
                        address, who claimed that the owner of the destination
                        address failed to deliver the agreed development
                        services on the app as per the attached deal. After
                        receiving this notification, we requested both parties
                        to provide any relevant documentation or correspondence
                        relating to the dispute, such as contracts, invoices,
                        emails, or other communication.
                      </p>
                      <p>
                        After a thorough review of the information provided by
                        both parties, our AI technology has determined that the
                        owner of the destination address did not fulfill the
                        terms of the attached deal and failed to deliver the
                        agreed development services on the app. Therefore, we
                        rule in favor of the owner of the origin address.
                      </p>
                      <p>Ruling: {item.dispute.ruling}</p>
                      <p>
                        Please note that this ruling is final and binding. We
                        appreciate your cooperation and understanding throughout
                        this process. If you have any further questions or
                        concerns, please do not hesitate to contact us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default DetailedHistory;
