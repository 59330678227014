import axios from "axios";
import useVar from "../hooks/useVar";
import { useCallback } from "react";

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  // withCredentials: true,
  timeout: 15000,
});

const useAxios = () => {
  const { signature } = useVar();

  const post = useCallback(
    async (url, body) => {
      console.log(url);

      body ??= {};
      body.signature = signature;
      return await myAxios.post(url, body);
    },
    [signature]
  );

  return { myAxios, ownerAxios: { post } };
};

export default useAxios;
