import { useState } from "react";
import useVar from "../hooks/useVar";
import CancelButton from "./buttons/CancelButton";
import NextButton from "./buttons/NextButton";
import { ethers } from "ethers";
import ScWalletCreateDone from "./ScWalletCreateDone";

const ScWalletImportMemonic = () => {
  const [mnemonic, setMnemonic] = useState("");
  const [error, setError] = useState(false);
  const { modal } = useVar();

  return (
    <div className="space-y-6">
      <div></div>
      <div className="space-y-2">
        <div className="text-base font-medium text-[var(--chakra-colors-gray-400)] ">
          Type or paste your Secret Recovery Phrase in all lower case with no
          commas or numbers and a single space between each word. 
          {/* thumb nephew
          post again gasp coin remind grain scare toast harsh burden */}
        </div>
        <div className="h-36">
          <textarea
            type="text"
            id="mnemonic"
            autoFocus={true}
            className="h-full w-full rounded-xl text-sm opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            minLength={10}
            maxLength={1000}
            rows={6}
            value={mnemonic}
            onChange={(e) => {
              setMnemonic(e.target.value);
              setError(false);
            }}
            required
            // focus
            // autoFocus={true}
          />
        </div>

        <div className="text-xs text-gray-600 mb-5 text-right">
          ({mnemonic.length}/1000)
        </div>
      </div>
      <div className="space-y-2">
        <NextButton
          text={{
            disabled: "Please insert a mnemonic",
            enabled: "Next",
            error: "Invalid mnemonic",
          }}
          disabled={mnemonic.length < 12 * 4}
          error={error}
          onClick={() => {
            try {
              const userWallet = ethers.Wallet.fromMnemonic(mnemonic);
              const wallet = {
                signMessage: userWallet.signMessage.bind(userWallet),
                private_key: userWallet.privateKey.toLowerCase(),
                public_key: userWallet.address.toLowerCase(),
                mnemonic: userWallet.mnemonic.phrase.split(" "),
              };

              modal.push(
                "Your Wallet is Self-Custody",
                <ScWalletCreateDone wallet={wallet} />
              );
            } catch (e) {
              setError(true);
            }
          }}
        />
        <CancelButton />
      </div>
    </div>
  );
};

export default ScWalletImportMemonic;
