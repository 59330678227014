import { useState } from "react";
import moment from "moment";
import { Transition } from "@headlessui/react";
import useVar from "../../../hooks/useVar";
import useAxios from "../../../hooks/useAxios";
import { RiDeleteBin6Line } from "react-icons/ri";
import MailBody from "./MailBody";

export default function MainSidebar({
  index,
  msg,
  setActiveIndex,
  activeIndex,
}) {
  const { setIsLoading } = useVar();
  const { ownerAxios } = useAxios();
  const [open, setOpen] = useState(false);

  const { _id } = msg;
  const onClickRead = async () => {
    setActiveIndex(index);

    setOpen(!open);
    if (msg.read) return;

    try {
      ownerAxios.post("/readMail/", { _id });
    } catch (err) {
      console.log(err);
    }
  };
  const onClickDelete = async (action) => {
    setActiveIndex(index);

    setIsLoading(true);

    try {
      ownerAxios.post("/deleteMail/", { _id });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        onClick={onClickRead}
        className={`
    ${
      index === activeIndex
        ? "bg-[var(--chakra-colors-gray-700)] "
        : "bg-[var(--chakra-colors-gray-750)] "
    } 
    ${!msg?.read && "font-extrabold "}
   " block px-6 py-4 border-b border-[var(--chakra-colors-gray-600)] text-left mx-0" `}
      >
        <div className="flex justify-between items-center">
          <span
            className={`text-sm font-semibold text-gray-100 ${
              !msg?.read && "font-extrabold "
            } `}
          >
            {msg.from}
          </span>
          <div className="flex justify-between items-center space-x-2">
            <span className="text-xs text-gray-100">
              {moment(msg?.created).fromNow()}
            </span>
            <RiDeleteBin6Line
              onClick={onClickDelete}
              className="w-7 h-7 cursor-pointer hover:bg-bg p-1.5 rounded-full
             transition-all  duration-300 ease-in-out transform"
            />
          </div>
        </div>
        <div
          className={`
          " block  text-left mx-0" `}
        >
          <p className="mt-2 text-gray-400">Subject: {msg.subject}</p>
        </div>
      </button>

      <div className="xs:hidden">
        <Transition
          show={open}
          enter="transition duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave=" transition duration-300 "
          leaveFrom="opacity-100 scale-100 "
          leaveTo="opacity-0 scale-95 "
        >
          <MailBody msg={msg} />
        </Transition>
      </div>
    </>
  );
}
