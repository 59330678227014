import useVar from "../hooks/useVar";

import MultiButton from "../modals/buttons/MultiButton";
import InviteName from "../modals/InviteName";

const InviteButton = () => {
  const { modal } = useVar();

  const onSubmit = async (e) => {
    e.preventDefault();

    modal.pushWithLoading("Invite a Friend", <InviteName />);
  };

  return (
    <div className="px-6 py-4 w-full bg-[var(--chakra-colors-gray-785)] border border-gray-800 sm:rounded-xl shadow-md">
      <form onSubmit={onSubmit}>
        <MultiButton
          state={{
            type: "action",
            text: "Invite a friend",
          }}
        />
      </form>
    </div>
  );
};

export default InviteButton;
