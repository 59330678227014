import { RxCross2 } from "react-icons/rx";
// import { BiRefresh } from "react-icons/bi";
import { Fragment } from "react";

import ScIcon from "../assets/coins/sc.svg";
import EtherIcon from "../assets/coins/ether.png";

import useVar from "../hooks/useVar";
import { shortenAddress } from "../js/utils";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import HeaderLogo from "./HeaderLogo";
import CopyToClipboard from "./CopyToClipBoard";

const HeaderRightAddress = () => {
  const { walletProvider } = useVar();

  return (
    <Menu.Button>
      <div
        className="flex justify-center items-center space-x-4 p-2 text-sm font-semibold border border-gray-100 border-opacity-20 
                    rounded-xl hover:bg-gray-100 hover:bg-opacity-10 transition-opacity duration-500 
                    text-gray-100"
      >
        <div className="ml-2 w-6 h-6">
          <img src={walletProvider?.icon} alt="" />
        </div>

        <div className="bg-[var(--chakra-colors-gray-800)] rounded-lg px-2">
          {walletProvider?.shortaddress}
        </div>
        <div className=" ">
          <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
        </div>
      </div>
    </Menu.Button>
  );
};

const HeaderRightMenu = () => {
  const { walletProvider, walletAddress } = useVar();

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        className="absolute right-0 mt-2 w-56 origin-top-right 
                   rounded-xl bg-[var(--chakra-colors-gray-750)] shadow-lg border border-white border-opacity-10
                    focus:outline-none min-w-[20rem] divide-y divide-gray-400 divide-opacity-20"
      >
        <div className="p-4 ">
          <div className="text-sm  font-semibold text-[var(--chakra-colors-gray-500)]">
            Connected Wallet
          </div>

          <div className="flex mt-4 items-center space-x-4 opacity-40 cursor-not-allowed">
            <img className="w-6 h-6" src={walletProvider?.icon} alt="" />

            <div className="text-base font-semibold ">
              {walletProvider?.name}
            </div>
          </div>
        </div>

        <div className="p-4 ">
          <div className="text-sm  font-semibold text-[var(--chakra-colors-gray-500)]">
            Active Addresses
          </div>

          <div className="flex mt-4 items-center space-x-4 ">
            <img className="w-6 h-6" src={ScIcon} alt="" />
            <div className="text-base font-semibold ">
              <CopyToClipboard copy={walletAddress}>
                {shortenAddress(walletAddress)}
              </CopyToClipboard>
            </div>
          </div>
          {walletProvider && (
            <div className="flex mt-4 items-center space-x-4 ">
              <img className="w-6 h-6" src={EtherIcon} alt="" />
              <div className="text-base font-semibold ">
                <CopyToClipboard copy={walletProvider.address}>
                  {walletProvider.shortaddress}
                </CopyToClipboard>
              </div>
            </div>
          )}
        </div>

        <div className="p-4 flex flex-col space-y-3">
          {/* <button disabled={walletProvider} onClick={() => {}} className={` `}>
            { <div
              className={`${
                walletProvider && " opacity-20 "
              }  flex items-center space-x-2`}
            >
              <BiRefresh className="text-xl " />
              {/* <div className="font-medium text-base">
                Connect {walletProvider.name}
              </div> 
            </div> 
            </button> */}
          <button onClick={walletProvider.logout} className={""}>
            <div className=" text-red-500  flex items-center space-x-2">
              <RxCross2 className="text-xl " />
              <div className="font-medium text-base">Disconnect</div>
            </div>
          </button>{" "}
        </div>
      </div>
    </Transition>
  );
};

const Header = () => {
  return (
    <nav className=" fixed top-0 z-20 w-full bg-white border-b border-gray-200 dark:bg-bg dark:border-[var(--chakra-colors-gray-750)]">
      <div className="flex items-center justify-between px-5 py-4 max-md:pt-14">
        <HeaderLogo />
        <div className="flex items-center space-x-4">
          <Menu as="div" className="relative inline-block text-left">
            <HeaderRightAddress />
            <HeaderRightMenu />
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Header;
