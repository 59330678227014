// import XyChart from "../components/XyChart";
import InviteButton from "../components/InviteButton";
import Trade from "../components/Trade";
import Recent from "../components/Recent";
import WalletAssets from "../components/WalletAssets";
import NewBalances from "../components/NewBalances";
import useNetWorth from "../hooks/useNetWorth";
import icons from "../js/icons";
import useAttentionModal from "../hooks/useAttentionModal";

const Dashboard = () => {
  const { netWorth } = useNetWorth();

  useAttentionModal();

  return (
    <div className="min-h-full bg-bg border-[var(--chakra-colors-gray-750)]">
      <div className="p-4 px-8 flex items-center space-x-6   bg-[var(--chakra-colors-gray-900)] border-[var(--chakra-colors-gray-750)] border">
        <div className="font-medium text-xl ">Assets</div>
        <div className="flex space-x-4 items-center">
          <div className="-mt-1 w-4 h-4 text-yellow-400">{icons.barak}</div>
          <div className="text-[var(--chakra-colors-gray-500)]">Net Worth</div>
          <div className="font-bold text-xl">$ {netWorth}</div>
        </div>
      </div>

      <NewBalances />
      <div className="sm:p-8 sm:flex  justify-between sm:space-x-4">
        <div className="w-full space-y-6">
          {/* <XyChart /> */}
          <WalletAssets />
        </div>
        <div className="w-full sm:max-w-sm space-y-6">
          <InviteButton />
          <Trade />
          <Recent />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
