import { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";
import axios from "axios";
// import { ImSpinner2 } from "react-icons/im";
import useVar from "../hooks/useVar";
/* <ImSpinner2 className="w-5 h-5 animate-spin" /> */
const useGas = () => {
  const { walletProvider } = useVar();
  const { provider, tokens, signer } = walletProvider;

  const [gas, setGas] = useState(2);

  const getGasPriceGwei_ = useCallback(async () => {
    // Fetch the gas price in Gwei

    if (!provider) return 0;

    const gasPrice = await provider.getGasPrice();
    return gasPrice;
  }, [provider]);

  const estimateContractGasCost_ = useCallback(
    async (gasPrice) => {
      if (!tokens || !tokens["USDT"]) return 0;

      try {
        console.log("tokens", tokens["USDT"]);
        // Estimate the gas cost for sending 100 USDT to a specific address
        const gasEstimate = await tokens["USDT"].contract
          .connect(signer)
          .estimateGas.transfer(
            "0xA603eA61a28Ca52a46aCe048188Cb4D2D0A89678",
            ethers.utils.parseUnits("1", 6)
          );

        console.log(
          "Estimated gas cost for transferring USDT:",
          gasEstimate.toString()
        );
        return gasEstimate;
      } catch (error) {
        console.error("Error:", error);
        return 0;
      }
    },
    [tokens, signer]
  );

  const convertGwiToUSD_ = useCallback(async (gwi) => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const ethPriceInUSD = response.data.ethereum.usd;
      const gasCostInUSD =
        parseFloat(ethers.utils.formatEther(gwi)) * ethPriceInUSD;

      console.log("Total Gas cost in USD:", gasCostInUSD);
      return gasCostInUSD.toFixed(2);
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const gasPrice = await getGasPriceGwei_();
        const gasConst = await estimateContractGasCost_(gasPrice);
        const totalGasPriceInUSD = await convertGwiToUSD_(gasPrice * gasConst);

        setGas(totalGasPriceInUSD);
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, [estimateContractGasCost_, getGasPriceGwei_, convertGwiToUSD_]);

  return gas;
};

export default useGas;
