import svg from "../assets/coins/sc_bw.svg";

import Canvas from "../components/Canvas";
import { useParams } from "react-router";
import useVar from "../hooks/useVar";

import { toast } from "react-hot-toast";

import { Fragment, useState } from "react";
import { Listbox, Transition, Disclosure } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { buy_coins } from "../js/coins";

import { useLocation, Navigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PayComponent = () => {
  const { secret, walletAddress, restApi, balances, setRampModal } = useVar();
  const [selected, setSelected] = useState(buy_coins[0]);
  const { encoded } = useParams();

  const payload = JSON.parse(decodeURIComponent(encoded));

  const onBuy = () => {
    if (selected.ticker === "Credit Card") {
      setRampModal(true);
      return;
    }
    restApi.buy({
      walletAddress,
      symbol: selected.ticker,
      amount: payload.amount * 10 ** 9,
      secret,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (payload.wallet === "0x") {
      return toast.success("This is a Demo.", {
        duration: 5000,
      });
    }
    if (balances.sc < payload.amount) {
      return toast.error(
        "Not enough balance, you can buy more SafeCoins below"
      );
    }

    // restApi.createTx({
    //   recipient: payload.wallet,
    //   secret,
    //   payload: {
    //     action: "REQUEST_TRANSFER",
    //     amount: Math.floor(payload.amount * 10 ** 9),
    //     description: payload.description,
    //   },
    //   // msg: `Sending ${amount} SafeCoin to ${wallet.slice(0, 5)}...`,
    //   msg: `Transaction of transfer ${payload.amount} SafeCoin has been initiated!`,
    // });
  };

  return (
    <section className="bg-bgMain bg-cover   bg-bottom flex flex-col justify-center items-center  min-h-screen ">
      {/* {walletAddress && <Header />} */}
      <Canvas />

      <div
        className="shadow-2xl shadow-indigo-300 bg-black z-20 bg-opacity-50 
      rounded-2xl flex items-center justify-center p-10 my-10
       "
      >
        <div className="flex items-center justify-center flex-col">
          <img src={svg} loading="lazy" alt="" className="h-16" />
          <h2 className="mt-3 text-center text-3xl font-light tracking-tight text-gray-100 capitalize underline ">
            Pay by SafeCoin
          </h2>

          <form className="mt-8 space-y-6 w-full " onSubmit={handleSubmit}>
            <div className="-space-y-px rounded-md shadow-sm mb-10 border border-gray-300 p-5 bg-black bg-opacity-20">
              <div className="flex">
                <div className="text-gray-300 text-gra">Current Balance: </div>
                <div className="text-gray-100 font-bold ml-2">
                  {balances.sc} SC
                </div>
              </div>{" "}
              <div className="flex">
                <div className="text-gray-300 text-gra">Pay of: </div>
                <div className="text-gray-100 font-bold ml-2">
                  {payload.amount} SC
                </div>
              </div>
              <div className="flex">
                <div className="text-gray-300">To: </div>
                <div className="text-gray-100 font-bold ml-2">
                  {payload.name}
                </div>
              </div>
              <div className="flex">
                <div className="text-gray-300">Description: </div>
                <div className="text-gray-100 font-bold ml-2">
                  {payload.description}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className=" w-1/2 mx-auto group relative flex justify-center rounded-md border border-transparent bg-indigo-600 
                py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              Pay Now
            </button>
          </form>
          <div className="w-full px-4 mt-5 ">
            <div className="mx-auto w-full max-w-md rounded-2xl ">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="flex w-full justify-between rounded-lg px-4 py-2 
              font-medium text-gray-400 text-base my-1 text-center "
                    >
                      <span>Buy SC Now</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-100">
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <div className="relative mt-3">
                              <Listbox.Button
                                className="relative w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm
                 focus:border-gray-500 focus:outline-none sm:text-sm hover:bg-bg"
                              >
                                <span className="flex items-center">
                                  {selected.image ? (
                                    <img
                                      src={selected.image}
                                      alt=""
                                      className="h-6 w-6 flex-shrink-0 rounded-full"
                                    />
                                  ) : (
                                    selected.icon
                                  )}

                                  <span className="ml-3 block truncate">
                                    {selected.name}
                                  </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                className={`${open ? "-mt-48" : "mt-1"}`}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md 
                                  bg-bg py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
                                  sm:text-sm"
                                >
                                  {buy_coins.map((item, index) => (
                                    <Listbox.Option
                                      key={index}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-indigo-600"
                                            : "text-white",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={item}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="flex items-center">
                                            {item.image ? (
                                              <img
                                                src={item.image}
                                                alt=""
                                                className="h-6 w-6 flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              item.icon
                                            )}
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "ml-3 block truncate"
                                              )}
                                            >
                                              {item.name}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-indigo-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>

                      <button
                        onClick={onBuy}
                        className="mt-5 mx-auto group relative flex justify-center rounded-md border border-transparent bg-indigo-600 
                py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700"
                      >
                        {/* <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span> */}
                        Buy Now
                      </button>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Pay = () => {
  const { secret } = useVar();
  const location = useLocation();

  return secret ? (
    <PayComponent />
  ) : (
    <Navigate to="/Dashborad" state={{ from: location }} replace />
  );
};
export default Pay;
