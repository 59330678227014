// import useVar from "../hooks/useVar";
import LinkLoading from "./LinkLoading";
import icons from "../js/icons";
import useVar from "../hooks/useVar";

const NavItem = ({ name, link, count }) => {
  return (
    <LinkLoading
      to={link}
      smooth="true"
      duration={500}
      className=" w-20 p-2 focus:text-indigo-400 hover:text-gray-200 hover:bg-opacity-80
         hover:bg-slate-800 hover:rounded-lg 
                       justify-center space-y-2 text-center items-center flex flex-col "
    >
      <div className="w-6 h-6">
        {icons[name.split(" ")[0].toLowerCase()]}

        {count > 0 && (
          <span
            className="-top-2 left-7 absolute px-1.5 bg-blue-500 text-white leading-5 text-xs
        rounded-full"
          >
            {count}
          </span>
        )}
      </div>

      <div className="text-xs">{name}</div>
    </LinkLoading>
  );
};

const BottomBar = () => {
  const { historyCount } = useVar();
  return (
    //

    <section className="md:hidden fixed inset-x-0 bottom-0 z-10 shadow bg-opacity-97  bg-slate-900 hide-on-mobile">
      <div className="flex justify-around p-2 max-sm:mb-3">
        <NavItem name={"My Wallet"} link={"/Dashboard"} />
        <NavItem name={"Assets"} link={"/Assets/0"} />
        <NavItem name={"Transactions"} link={"/History"} count={historyCount} />
        <NavItem name={"Trade"} link={"/Trade"} />
      </div>
    </section>
  );
};

export default BottomBar;
