import { useState } from "react";
import useVar from "../hooks/useVar";
import InviteEmail from "./InviteEmail";
import CancelButton from "./buttons/CancelButton";
import NextButton from "./buttons/NextButton";

const InviteName = () => {
  const [name, setName] = useState("");
  const { modal } = useVar();

  return (
    <div className="space-y-10">
      <div className=""></div>
      <div className="space-y-2">
        <div className="text-base font-semibold text-[var(--chakra-colors-gray-500)] ">
          Invite
        </div>
        <div className="relative">
          <input
            type="text"
            autoFocus={true}
            className="h-12 w-full  rounded-xl opacity-90  focus:bg-[var(--chakra-colors-gray-900)] hover:bg-[var(--chakra-colors-gray-900)]
                      border-2 border-[var(--chakra-colors-gray-750)]
                     bg-[var(--chakra-colors-gray-850)] focus:border-[var(--chakra-colors-gray-750)] focus:ring-0
                     focus-visible:border-[var(--border-input)] focus-visible:border-2
                     transition-all"
            id="name"
            maxLength="66"
            minLength="66"
            placeholder="Friend Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
      </div>

      <div className="space-y-2">
        <NextButton
          text={{
            disabled: "Please insert your friend name",
            enabled: "Next",
          }}
          disabled={name.length === 0}
          onClick={() => {
            modal.push("Invite a Friend", <InviteEmail name={name} />);
          }}
        />
        <CancelButton />
      </div>
    </div>
  );
};

export default InviteName;
