import ModalContext from "./Context.js";
import useFetch from "../hooks/useFetch.js";
import useHistory from "../hooks/useHistory.js";
import useModal from "../hooks/useModal.js";
import useBalances from "../hooks/useBalances.js";
import useSocketIo from "../hooks/useSocketIo.js";
import useWalletProvider from "../hooks/useWalletProvider.js";
import useLoading from "../hooks/useLoading.js";

import { useState } from "react";

const Provider = ({ children }) => {
  const [isLoading, setIsLoading, runLoading] = useLoading(false);
  const [showBottomBar, setShowBottomBar] = useState(true);

  const walletProvider = useWalletProvider({ isLoading, setIsLoading });
  const {
    receivedMessage,
    setReceivedMessage,
    account,
    inbox,
    walletAddress,
    ruling,
  } = useSocketIo(walletProvider.secret, setIsLoading);

  const [history, historyCount] = useHistory(account);
  const [exchangeRates] = useFetch("/getExchangeRates");
  const [networkName] = useFetch("/networkName");

  const modal = useModal(receivedMessage, setReceivedMessage, runLoading);
  const [balances] = useBalances(account, receivedMessage, walletProvider);

  return (
    <ModalContext.Provider
      value={{
        walletProvider,
        networkName,
        balances,
        history,
        historyCount,
        walletAddress,
        secret: walletProvider.secret,
        signature: walletProvider.signature,
        provider: walletProvider.provider,
        runLoading,
        isLoading,
        setIsLoading,
        exchangeRates,
        modal,
        receivedMessage,
        setReceivedMessage,
        inbox,
        ruling,
        showBottomBar,
        setShowBottomBar,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default Provider;
