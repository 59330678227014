import image from "../assets/illustrations/Error/404.svg";

const NotFound = () => {
  return (
    <section>
      <div>
        <div>
          <center>
            {/* <h1>Page 404 not found</h1> */}
            <img src={image} alt="" width="600" />
          </center>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
